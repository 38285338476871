export enum Colors {
  White = "#FFFFFF",
  Black = "#0A1522",

  LightGray = "#F2F2F2",
  Gray = "#A0A0A0",
  BleachedGray = "#E6E7E9",
  BleachedGrayDark = "#D6D6D6",
  DarkGray = "#2F2F2F",
  DarkGrayLighter = "#6A6A6A",

  MainYellow = "#EACF1A",
  MainYellowDark = "#D7BD04",

  BleachedGreen = "#D9F4EF",
  BleachedGreenDark = "#C8E4DF",
  Green = "#00B196",

  BleachedOrange = "#FFE9CE",
  BleachedOrangeDark = "#F7D7C4",
  Orange = "#F6762E",

  BleachedRed = "#FEE7E0",
  BleachedRedDark = "#FED3C6",
  Red = "#F65E2E",

  AccentBlue = "#4A85F7",
}
