import { Severity } from "@novalabsxyz/constants/severity";

export enum OnOffStatus {
  Off = "off",
  On = "on",
}

export const boolToOnOffStatus = (value?: boolean): OnOffStatus => {
  switch (value) {
    case true:
      return OnOffStatus.On;
    case false:
    case undefined:
    default:
      return OnOffStatus.Off;
  }
};

export const onOffToSeverity = (status: OnOffStatus = OnOffStatus.Off): Severity => {
  const statusMap = {
    [OnOffStatus.Off]: Severity.Error,
    [OnOffStatus.On]: Severity.Success,
  };

  return statusMap[status];
};
