import { memo } from "react";

import { SvgIcon } from "@mui/material";
import type { SvgIconProps } from "@mui/material";

import { ReactComponent as FileFilledGreenSvg } from "./svg/file-filled-green.svg";
import { ReactComponent as QuestionMarkOutlinedSvg } from "./svg/question-mark-outlined.svg";

export const FileFilledGreenIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={FileFilledGreenSvg} {...props} />
));
FileFilledGreenIcon.displayName = "FileFilledGreenIcon";

export const QuestionMarkOutlinedIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={QuestionMarkOutlinedSvg} {...props} />
));
QuestionMarkOutlinedIcon.displayName = "QuestionMarkOutlinedIcon";
