import { memo, useCallback, useMemo } from "react";

import { useField, useFormikContext } from "formik";

import { NumberField } from "@novalabsxyz/components/core";
import type { NumberFieldProps } from "@novalabsxyz/components/core";

export interface FormNumberFieldProps
  extends Omit<NumberFieldProps, "value" | "onChange" | "error"> {}
export const FormNumberField = memo<FormNumberFieldProps>(
  ({ name, helperText, disabled, readOnly, ...props }) => {
    const [{ value }, { touched, error }, { setTouched, setValue }] = useField<number | null>(name);
    // TODO: Add initial status type to Formik.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { isSubmitting, validateOnMount, status } = useFormikContext();

    const handleBlur = useCallback(() => {
      setTouched(true);
    }, [setTouched]);

    return useMemo(
      () => (
        <NumberField
          {...props}
          name={name}
          value={value}
          onChange={setValue}
          onBlur={handleBlur}
          readOnly={readOnly || status === "readOnly"}
          disabled={isSubmitting || disabled}
          error={(validateOnMount || touched) && error !== undefined}
          helperText={((validateOnMount || touched) && error) || helperText}
        />
      ),
      [
        name,
        value,
        error,
        helperText,
        disabled,
        touched,
        isSubmitting,
        readOnly,
        validateOnMount,
        status,
        props,
        setValue,
        handleBlur,
      ],
    );
  },
);
FormNumberField.displayName = "FormNumberField";
