import { memo } from "react";

import type { StepperProps as MuiStepperProps } from "@mui/material";
import { Stepper as MuiStepper, Step, StepLabel } from "@mui/material";

export interface StepperProps extends MuiStepperProps {
  steps: string[];
}

export const Stepper = memo<StepperProps>(({ steps, ...props }) => (
  <MuiStepper {...props}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </MuiStepper>
));
Stepper.displayName = "Stepper";
