export enum RadioModel {
  FreedomFiOne = "Freedomfi One",
  MocoLabsOutdoor = "MosoLabs Outdoor",
  Baicells430H = "Baicells 430H",
  Baicells436H = "Baicells 436H",
}

export interface RadioInfo {
  productClassParts?: string[];
}

export const radiosInfo: { [key in RadioModel]: RadioInfo } = {
  [RadioModel.FreedomFiOne]: { productClassParts: ["HeNB-TDD-Enterprise", "SCE4255W"] },
  [RadioModel.MocoLabsOutdoor]: { productClassParts: ["SCO4255P-BC-A10"] },
  [RadioModel.Baicells430H]: { productClassParts: ["pBS3101S", "pBS3101SH"] },
  [RadioModel.Baicells436H]: { productClassParts: ["mBS31001", "mBS31001H"] },
};

export const radioProductClassToModel = (productClass?: string): RadioModel | undefined => {
  if (!productClass) {
    return undefined;
  }

  const model = Object.values(RadioModel).find((modelCandidate) => {
    const { productClassParts } = radiosInfo[modelCandidate];

    if (!productClassParts) {
      return false;
    }
    return productClassParts.some((productClassPart) => productClass.includes(productClassPart));
  });

  return model;
};
