import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Config } from "@novalabsxyz/agw-client/config";

export enum UpgradeAgwResponseStatus {
  OnLatestVersion = 0,
  StartingUpdate = 1,
  FailedToCheck = 2,
}

export const UpgradeAgwResponseMessageByStatus = {
  [UpgradeAgwResponseStatus.OnLatestVersion]: "You are on the latest version",
  [UpgradeAgwResponseStatus.StartingUpdate]: "Starting update",
  [UpgradeAgwResponseStatus.FailedToCheck]: "Failed to check Gateway version",
};

export type UpgradeAgwResponse = {
  status: UpgradeAgwResponseStatus;
  message: string;
};

export const minerApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: Config.AgwApiBaseUrl }),
  reducerPath: "miner",
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    resetCloudConnection: builder.mutation<void, void>({
      query: () => ({
        url: "/reset-cloud-connection",
        method: "POST",
      }),
    }),
    rebootDevice: builder.mutation<void, void>({
      query: () => ({
        url: "/reboot-device",
        method: "POST",
      }),
    }),
    upgradeAgw: builder.mutation<UpgradeAgwResponse, void>({
      query: () => ({
        url: "/upgrade/force",
        method: "POST",
      }),
    }),
    restartMiner: builder.mutation<void, void>({
      query: () => ({
        url: "/restart-miner",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useRebootDeviceMutation,
  useUpgradeAgwMutation,
  useResetCloudConnectionMutation,
  useRestartMinerMutation,
} = minerApi;
