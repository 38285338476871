import type { ReactNode } from "react";
import { memo } from "react";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import type { FormControlLabelProps } from "@mui/material";

import { startCase } from "@novalabsxyz/utils/lodash-plus";

export interface CheckboxProps
  extends Pick<FormControlLabelProps, "label" | "value" | "disabled" | "onChange" | "onBlur"> {
  name: string;
  error?: boolean;
  helperText?: ReactNode;
}

export const Checkbox = memo<CheckboxProps>(
  ({ name, label, value, disabled, error, helperText, ...rest }) => (
    <FormControl id={name} error={error}>
      <FormControlLabel
        disabled={disabled}
        name={name}
        value={value}
        label={label || startCase(name)}
        control={<MuiCheckbox />}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  ),
);
Checkbox.displayName = "Checkbox";
