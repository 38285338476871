import { memo } from "react";

import {
  GpsStatus,
  gpsStatusToSeverity,
  SpectrumStatus,
  spectrumStatusToSeverity,
} from "@novalabsxyz/agw-api-client";
import type { RadioInfo } from "@novalabsxyz/agw-api-client";
import { useSerialIdQuery } from "@novalabsxyz/agw-client/store/api/gateway-stats";
import { useUeCountQuery } from "@novalabsxyz/agw-client/store/api/ue";
import { Box, Stack, Grid, Typography, Alert } from "@novalabsxyz/components/core";
import { StatusOneLineCard } from "@novalabsxyz/components/ui/cards";
import { Severity } from "@novalabsxyz/constants/severity";
import { connectionToSeverity, onOffToSeverity } from "@novalabsxyz/constants/status";
import { TimeInMilliseconds } from "@novalabsxyz/constants/time";
import { RadioRegistrationDialogTarget } from "@novalabsxyz/features/radios/registration";
import { tenantContent } from "@novalabsxyz/tenants";

import { useRadioInfoException } from "./use-radio-info-exception";

export interface RadioMetricsProps {
  radioInfo: RadioInfo;
}
export const RadioMetrics = memo<RadioMetricsProps>(({ radioInfo }) => {
  useRadioInfoException(radioInfo);

  const { data: { serialId: gatewaySerialNumber } = {}, isError: isGatewaySerialNumberError } =
    useSerialIdQuery();

  const { data: numberOfConnectedDevices } = useUeCountQuery(undefined, {
    pollingInterval: TimeInMilliseconds.OneMinute,
  });

  return (
    <Box
      sx={{
        paddingY: radioInfo.isActivated
          ? {
              xs: 4,
              sm: 5,
              md: 6,
            }
          : {
              xs: 2,
              sm: 3,
              md: 4,
            },
      }}
    >
      <Stack spacing={8}>
        <Box sx={{ position: "relative" }}>
          <Stack spacing={8}>
            <Box>
              <Grid
                container
                spacing={{
                  xs: 2,
                  sm: 3,
                  md: 4,
                }}
              >
                {!radioInfo.isActivated && (
                  <Grid item xs={12}>
                    <Box>
                      <Alert title="License activation" severity={Severity.Error}>
                        This radio license has not been activated. Please contact the vendor you
                        purchased it from with the serial number and request activation.
                      </Alert>
                    </Box>
                  </Grid>
                )}
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="GPS Status"
                    textVariant="h5"
                    value={GpsStatus[radioInfo.gpsStatus]}
                    valueVariant="contained"
                    severity={gpsStatusToSeverity(radioInfo.gpsStatus)}
                    tooltipProps={{
                      title:
                        "The Small Cell requires GPS to operate. GPS status will say locked when it has found enough satellites.",
                    }}
                    tooltipTarget="title"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="Spectrum Access"
                    textVariant="h5"
                    value={SpectrumStatus[radioInfo.spectrumStatus]}
                    valueVariant="contained"
                    severity={spectrumStatusToSeverity(radioInfo.spectrumStatus)}
                    tooltipProps={{
                      title: (
                        <>
                          <Typography paragraph variant="body2">
                            Status of the connection to spectrum access system required to use CBRS
                            spectrum.
                          </Typography>
                          {radioInfo.dpEnabled && radioInfo.dpResponseCode !== undefined && (
                            <>
                              <Typography paragraph variant="body2">
                                SAS status code: {radioInfo.dpResponseCode}
                              </Typography>
                              <Typography paragraph variant="body2">
                                SAS response message: {radioInfo.dpResponseMessage}
                              </Typography>
                            </>
                          )}
                        </>
                      ),
                    }}
                    tooltipTarget="title"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="Core Status"
                    textVariant="h5"
                    value={radioInfo.coreConnectionStatus}
                    valueVariant="contained"
                    severity={connectionToSeverity(radioInfo.coreConnectionStatus)}
                    tooltipProps={{
                      title: `This status indicates if the connection between the core in the ${tenantContent.gateway.name} and the Radio is established.`,
                    }}
                    tooltipTarget="title"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="On Air"
                    textVariant="h5"
                    value={radioInfo.airStatus}
                    valueVariant="contained"
                    severity={onOffToSeverity(radioInfo.airStatus)}
                    tooltipProps={{
                      title:
                        "On Air status tells you if your cell is up and can be seen by phones or not.",
                    }}
                    tooltipTarget="title"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                spacing={{
                  xs: 2,
                  sm: 3,
                  md: 4,
                }}
              >
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="Radio Model:"
                    textVariant="h5"
                    value={radioInfo.model}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="Device Serial:"
                    textVariant="h5"
                    value={radioInfo.serial}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="SW Version:"
                    textVariant="h5"
                    value={radioInfo.softwareVersion}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="Number of connected devices:"
                    textVariant="h5"
                    value={numberOfConnectedDevices}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <StatusOneLineCard
                    title="HW Version:"
                    textVariant="h5"
                    value={radioInfo.hardwareVersion}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
        {radioInfo.cpiEnabled && gatewaySerialNumber && (
          <Box
            sx={{
              paddingTop: {
                xs: 2,
                sm: 3,
                md: 4,
              },
            }}
          >
            <RadioRegistrationDialogTarget
              gatewaySerialNumber={gatewaySerialNumber}
              gatewayVendor={tenantContent.slug}
              radioInfo={radioInfo}
            />
          </Box>
        )}
        {isGatewaySerialNumberError ? (
          <Typography color="error">
            Failed to load Gateway Serial Number. Please try to refresh the page.
          </Typography>
        ) : null}
      </Stack>
    </Box>
  );
});
RadioMetrics.displayName = "RadioMetrics";
