import type { ReactNode } from "react";
import { memo } from "react";

import { Stack, Container } from "@novalabsxyz/components/core";
import { Footer } from "@novalabsxyz/containers/layout/components/footer";
import { Header } from "@novalabsxyz/containers/layout/components/header";

import backgroundUrl from "./assets/background.svg?url";

export interface LayoutProps {
  fixedContainer?: boolean;
  children?: ReactNode;
  headerContent?: ReactNode;
  footerContent?: ReactNode;
}

export const Layout = memo<LayoutProps>(
  ({ fixedContainer, children, headerContent, footerContent }) => (
    <Stack
      sx={{
        height: "100vh",
      }}
    >
      <Header fixedContainer={fixedContainer}>{headerContent}</Header>
      <Stack
        sx={{
          flex: 1,
          overflowY: "auto",
          backgroundImage: `url(${backgroundUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "left bottom",
          backgroundAttachment: "local",
        }}
      >
        <Container
          sx={{
            paddingY: {
              xs: 3,
              md: 4,
              lg: 5,
            },
            flex: 1,
          }}
          fixed={fixedContainer}
        >
          {children}
        </Container>
        <Footer fixedContainer={fixedContainer}>{footerContent}</Footer>
      </Stack>
    </Stack>
  ),
);
Layout.displayName = "Layout";
