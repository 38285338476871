import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { logger as loggerMiddleware } from "redux-logger";

import { parseResponseHttpError } from "@novalabsxyz/api-client-core";
import { ServiceConfig } from "@novalabsxyz/client-config";
import { EnvironmentType } from "@novalabsxyz/constants/env";
import { HttpStatus } from "@novalabsxyz/constants/http-status";
import { notificationsActions } from "@novalabsxyz/features/notifications/store";
import { routingService } from "@novalabsxyz/routing";
import { isDictionary } from "@novalabsxyz/utils/lodash-plus";

import { StoreConfig } from "./config";

export { loggerMiddleware };

export const rtkQueryErrorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const error = parseResponseHttpError(isDictionary(action) ? action.payload : {});
      if (error.status === HttpStatus.UNAUTHORIZED) {
        if (routingService.signOut) {
          routingService.signOut();
        }
        routingService.redirect("signIn");
      } else {
        dispatch(notificationsActions.openHttpError(error));
      }
    }

    next(action);
  };

export const systemMiddleware = [
  !StoreConfig.Logger.Disabled &&
  ([EnvironmentType.Staging, EnvironmentType.Development].includes(ServiceConfig.Environment) ||
    StoreConfig.Logger.Enabled)
    ? loggerMiddleware
    : undefined,
  rtkQueryErrorMiddleware,
].filter((middleware) => middleware !== undefined) as Middleware[];
