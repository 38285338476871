import type { IsAccessible } from "./utils";
import { transformAccessibiltyResponse } from "./utils";

export type MagmaAccess = IsAccessible;
export const magmaAccessQuery = {
  query: () => ({
    url: "/magma-access",
    method: "GET",
  }),
  transformResponse: transformAccessibiltyResponse,
};
