import type { Theme } from "./types";

export const isPaletteColorName = (
  color?: string,
): color is "primary" | "secondary" | "tertiary" | "info" | "success" | "warning" | "error" =>
  !!color &&
  ["primary", "secondary", "tertiary", "info", "success", "warning", "error"].includes(color);

export const getContrastColorInSxProp =
  (color?: string, fallbackToDark = false) =>
  ({ palette }: Theme): string => {
    if (isPaletteColorName(color)) {
      return palette[color].contrastText;
    }

    // TODO: This is simple implementation. Create proper contrast color determination on necessity.
    return fallbackToDark ? palette.text.primary : palette.background.paper;
  };

export const getColorInSxProp =
  (color?: string) =>
  ({ palette }: Theme): string | undefined => {
    if (isPaletteColorName(color)) {
      return palette[color].main;
    }

    if (color === "disabled") {
      return palette.action.disabled;
    }

    return undefined;
  };
