import { memo, useCallback } from "react";

import { useField, useFormikContext } from "formik";

import { FilesDropzone } from "@novalabsxyz/components/core";
import type { FilesDropzoneProps } from "@novalabsxyz/components/core";

export interface FormFilesDropzoneFieldProps
  extends Omit<FilesDropzoneProps, "files" | "error" | "onDrop" | "onFileDelete"> {}

export const FormFilesDropzoneField = memo<FormFilesDropzoneFieldProps>(
  ({ name, helperText, disabled, multiple, ...props }) => {
    const [{ value }, { touched, error }, { setValue }] = useField<File[]>(name);
    const { isSubmitting } = useFormikContext();

    const handleFileDelete = useCallback(
      (_file: File, index: number) => {
        setValue(value.filter((_filteredFile, filteredIndex) => filteredIndex !== index));
      },
      [value, setValue],
    );

    return (
      <FilesDropzone
        {...props}
        name={name}
        files={value}
        multiple={multiple}
        onDrop={setValue}
        onFileDelete={handleFileDelete}
        disabled={isSubmitting || disabled}
        error={touched && error !== undefined}
        helperText={(touched && error) || helperText}
      />
    );
  },
);
FormFilesDropzoneField.displayName = "FormFilesDropzoneField";
