import { memo, useState } from "react";

import type { RadioInfo } from "@novalabsxyz/agw-api-client";
import { useRadioStatsQuery } from "@novalabsxyz/agw-client/store/api/radio";
import { Stack, CircularLoader, Typography, Box } from "@novalabsxyz/components/core";
import { TimeInMilliseconds } from "@novalabsxyz/constants/time";
import { tenantContent } from "@novalabsxyz/tenants";

import { RadioInfoPanel } from "./radio-info-panel";
import { RadioSelect } from "./radio-select";

export const RadioDashboardPage = memo(() => {
  // TODO: Update to router usage for radio selection.
  // Possible path: "/radio/:radio-index".
  const [selectedRadioIndex, setSelectedRadioIndex] = useState(1);

  const { data, isLoading, refetch } = useRadioStatsQuery(undefined, {
    pollingInterval: TimeInMilliseconds.OneMinute,
  });

  let message;
  if (isLoading) {
    message = "Loading...";
  } else if (data === undefined) {
    message = (
      <>
        Failed to load radios information.
        <br />
        Please, try to reboot AGW and already connected radios.
        <br />
        If the problem still exists, download support logs and contact support.
      </>
    );
  } else if (!data.length) {
    message = `No supported radios are attached to ${tenantContent.gateway.name}`;
  }

  return (
    <Stack
      sx={{
        height: 1,
        alignItems: message && "center",
        justifyContent: message && "center",
      }}
    >
      <CircularLoader open={isLoading} type="itemOverlay" transparentBackground />
      {message && (
        <Typography
          sx={{
            alignSelf: "center",
            justifySelf: "center",
            fontSize: {
              xs: "h5.fontSize",
              md: "h4.fontSize",
            },
          }}
          fontWeight={400}
          component="p"
          align="center"
        >
          {message}
        </Typography>
      )}
      {!message && data?.length && (
        <Stack sx={{ width: 1, height: 1, alignSelf: "flex-start", justifySelf: "flex-start" }}>
          {data.length > 1 && (
            <RadioSelect
              onSelect={setSelectedRadioIndex}
              radioCount={data.length}
              selectedRadioIndex={selectedRadioIndex}
            />
          )}
          <Box sx={{ position: "relative", flex: 1 }}>
            <RadioInfoPanel
              radioInfo={data[selectedRadioIndex - 1] as RadioInfo}
              refetch={refetch}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
});
RadioDashboardPage.displayName = "RadioDashboardPage";
