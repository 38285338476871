import { memo, useCallback, useMemo } from "react";

import { useField, useFormikContext } from "formik";

import { TextField } from "@novalabsxyz/components/core";
import type { TextFieldProps } from "@novalabsxyz/components/core";

export interface FormTextFieldProps extends Omit<TextFieldProps, "value" | "onChange" | "error"> {}
export const FormTextField = memo<FormTextFieldProps>(
  ({ name, helperText, disabled, readOnly, ...props }) => {
    const [{ value }, { touched, error }, { setTouched, setValue }] = useField<string | null>(name);
    // TODO: Add initial status type to Formik.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { isSubmitting, validateOnMount, status } = useFormikContext();

    const handleValueChange = useCallback(
      (newValue: string) => {
        setValue(newValue === "" ? null : newValue);
      },
      [setValue],
    );

    const handleBlur = useCallback(() => {
      setTouched(true);
    }, [setTouched]);

    return useMemo(
      () => (
        <TextField
          {...props}
          name={name}
          value={value === null ? "" : value}
          onValueChange={handleValueChange}
          onBlur={handleBlur}
          readOnly={readOnly || status === "readOnly"}
          disabled={isSubmitting || disabled}
          error={(validateOnMount || touched) && error !== undefined}
          helperText={((validateOnMount || touched) && error) || helperText}
        />
      ),
      [
        name,
        value,
        error,
        helperText,
        disabled,
        touched,
        isSubmitting,
        readOnly,
        validateOnMount,
        status,
        props,
        handleValueChange,
        handleBlur,
      ],
    );
  },
);
FormTextField.displayName = "FormTextField";
