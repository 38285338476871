import { memo, useMemo, useCallback } from "react";

import { Stack, Box, Typography, IconButton } from "@mui/material";

import { ActionItemStatusCard } from "@novalabsxyz/components/ui/cards/action-item-status";
import { DeleteFileIcon } from "@novalabsxyz/icons";

export interface FilePreviewProps {
  file: File;
  index: number;
  onDelete: (file: File, index: number) => void;
  disabled?: boolean;
}

export const FilePreview = memo<FilePreviewProps>(({ file, index, disabled = false, onDelete }) => {
  const handleDelete = useCallback(() => {
    onDelete(file, index);
  }, [file, index, onDelete]);

  return useMemo(() => {
    const objectUrl = URL.createObjectURL(file);

    return (
      <ActionItemStatusCard
        disabled={disabled}
        sx={{
          height: 1,
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: 209,
          paddingX: 1,
          paddingY: 1,
        }}
      >
        <Stack sx={{ height: 1 }} spacing={1}>
          <Box sx={{ flex: 1 }}>
            <Stack sx={{ height: 109 }} alignItems="center" justifyContent="center">
              <Box
                component="img"
                sx={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  borderRadius: 1,
                  opacity: disabled ? 0.5 : 1,
                }}
                src={objectUrl}
                alt={file.name}
              />
            </Stack>
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={0.5}>
            <Typography
              sx={{
                paddingLeft: 0.5,
                maxHeight: 34,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              variant="body2"
              color={disabled ? "grey.400" : "textPrimary"}
            >
              {file.name}
            </Typography>
            <IconButton size="small" disabled={disabled} onClick={handleDelete}>
              <DeleteFileIcon />
            </IconButton>
          </Stack>
        </Stack>
      </ActionItemStatusCard>
    );
  }, [file, disabled, handleDelete]);
});
FilePreview.displayName = "FilePreview";
