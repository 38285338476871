import type { Severity } from "@novalabsxyz/constants/severity";
import type { ConnectionStatus } from "@novalabsxyz/constants/status";
import { boolToConnectionStatus, connectionToSeverity } from "@novalabsxyz/constants/status";

export type MinerConnectionStatusDTO = {
  isMinerConnected?: boolean;
};

export interface MinerConnectionStatus {
  status: ConnectionStatus;
  severity: Severity;
}

const transformResponse = (data: MinerConnectionStatusDTO): MinerConnectionStatus => {
  const status = boolToConnectionStatus(data.isMinerConnected);
  return {
    status,
    severity: connectionToSeverity(status),
  };
};

export const minerConnectionStatusQuery = {
  query: () => ({
    url: "/miner-connection-status",
    method: "GET",
  }),
  transformResponse,
};
