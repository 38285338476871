import { memo, useCallback, useMemo } from "react";
import type { ReactElement } from "react";

import { renderToString } from "react-dom/server";

import { AlertSnackbar, Box } from "@novalabsxyz/components/core";
import {
  notificationsActions,
  notificationsSelectors,
} from "@novalabsxyz/features/notifications/store";
import { useSelector, useDispatch } from "@novalabsxyz/store";
import { isArray } from "@novalabsxyz/utils/lodash-plus";

const PREFIX = "notification::";

export const stringifyNotification = (node: ReactElement): string =>
  `${PREFIX}${renderToString(node)}`;

const isStringifiedNotification = (candidate: string): boolean => candidate.startsWith(PREFIX);

const prepareStringifiedNotification = (notification: string): string => {
  if (!isStringifiedNotification(notification)) {
    throw new Error("String you are trying to parse is not a stringified notification.");
  }

  return notification.replace(PREFIX, "");
};

interface NotificationMessageProps {
  message: string | string[];
}

const NotificationMessage = memo<NotificationMessageProps>(({ message }) => {
  const parsedMessages = isArray(message) ? message : message.split("\n");

  const renderPart = (messagePart: string, index: number): ReactElement => {
    const isStringified = isStringifiedNotification(messagePart);

    return (
      <Box
        key={index}
        component={parsedMessages.length > 1 ? "li" : "span"}
        {...(isStringified
          ? { dangerouslySetInnerHTML: { __html: prepareStringifiedNotification(messagePart) } }
          : { children: messagePart })}
      />
    );
  };

  return parsedMessages.length > 1 ? (
    <Box
      component="ul"
      sx={{
        listStylePosition: "inside",
      }}
    >
      {parsedMessages.map(renderPart)}
    </Box>
  ) : (
    renderPart(parsedMessages[0] || "", 1)
  );
});
NotificationMessage.displayName = "NotificationMessage";

export const Notifications = memo(() => {
  const dispatch = useDispatch();

  const notifications = useSelector(notificationsSelectors.selectAll);

  const createCloseNotificationFunction = useCallback(
    (id?: string) => (): void => {
      if (!id) {
        return;
      }

      dispatch(notificationsActions.closeById(id));
    },
    [dispatch],
  );

  return useMemo(() => {
    const { id, message, title, severity } = notifications[0] || {};

    return (
      <AlertSnackbar
        key={id}
        open={!!id}
        onClose={createCloseNotificationFunction(id)}
        severity={severity}
        title={title}
      >
        <NotificationMessage message={message || ""} />
      </AlertSnackbar>
    );
  }, [notifications, createCloseNotificationFunction]);
});
Notifications.displayName = "Notifications";
