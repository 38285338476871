import { memo, useCallback, useMemo } from "react";
import type { ReactElement, ReactNode, NamedExoticComponent } from "react";

import { TableCell, TableRow, IconButton } from "@mui/material";

import { ExpandLessIcon, ExpandMoreIcon } from "@novalabsxyz/icons";

import type { TableOnRowExpand, TableRowBase, TableColumns } from "./types";

export interface SingleCellBodyRowProps {
  children: ReactNode;
}

export const SingleCellBodyRow = memo<SingleCellBodyRowProps>(({ children }) => (
  <TableRow>
    <TableCell sx={{ paddingY: 3 }} colSpan={100}>
      {children}
    </TableCell>
  </TableRow>
));
SingleCellBodyRow.displayName = "SingleCellBodyRow";

export interface BodyRowProps<T extends TableRowBase> {
  data: T;
  columns: TableColumns<T>;
  expanded?: boolean;
  onExpand?: TableOnRowExpand<T>;
  ExpandedRow?: NamedExoticComponent<T>;
}

const BodyRowNotMemoized = <T extends TableRowBase>({
  data,
  columns,
  expanded,
  onExpand,
  ExpandedRow,
}: BodyRowProps<T>): ReactElement => {
  const handleRowExpand = useCallback(() => {
    if (onExpand) {
      onExpand(expanded ? undefined : data);
    }
  }, [data, expanded, onExpand]);

  return useMemo(
    () => (
      <>
        <TableRow>
          {ExpandedRow && (
            <TableCell>
              <IconButton onClick={handleRowExpand}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </TableCell>
          )}
          {columns.map(({ id, renderCell, noWrap }) => (
            <TableCell
              key={id}
              sx={{
                whiteSpace: noWrap ? "nowrap" : "initial",
              }}
            >
              {renderCell ? renderCell(data) : data[id]}
            </TableCell>
          ))}
        </TableRow>
        {ExpandedRow && expanded && (
          <SingleCellBodyRow>
            <ExpandedRow {...data} />
          </SingleCellBodyRow>
        )}
      </>
    ),
    [data, columns, expanded, handleRowExpand, ExpandedRow],
  );
};
export const BodyRow = memo(BodyRowNotMemoized) as typeof BodyRowNotMemoized;
