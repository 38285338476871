import { isString, startCase } from "@novalabsxyz/utils/lodash-plus";

export interface GetLabelFromInputNameOptions {
  label?: string | boolean;
  name?: string;
  required?: boolean;
}

export const getLabelFromInputName = ({
  label,
  name = "",
  required,
}: GetLabelFromInputNameOptions): string =>
  `${isString(label) ? label : startCase(name)}${required ? "*" : ""}`;

export interface GetPlaceholderFromInputNameOptions extends GetLabelFromInputNameOptions {
  placeholder?: string;
}

export const getPlaceholderFromInputName = ({
  placeholder,
  label,
  name,
}: GetPlaceholderFromInputNameOptions): string =>
  placeholder || `Enter ${getLabelFromInputName({ label, name })}`;
