import { memo } from "react";

import { Stack, Button } from "@novalabsxyz/components/core";
import { DownloadIcon } from "@novalabsxyz/icons";
import { tenantContent } from "@novalabsxyz/tenants";

export const MinerSupport = memo(() => (
  <Stack direction="row" spacing={2}>
    <Button target="_blank" href={tenantContent.faq.url} size="small" color="secondary">
      {tenantContent.faq.label}
    </Button>
    <Button
      target="_blank"
      href="/api/v1/debug_log.tar.gz"
      download
      size="small"
      color="secondary"
      startIcon={<DownloadIcon />}
    >
      Support logs
    </Button>
  </Stack>
));
MinerSupport.displayName = "MinerSupport";
