import "@novalabsxyz/styles/index.css";

import { memo, StrictMode } from "react";

import { Provider } from "react-redux";

import { store } from "@novalabsxyz/agw-client/store";
import { Helmet } from "@novalabsxyz/components/seo";
import { Layout } from "@novalabsxyz/containers/layout";
import { Notifications } from "@novalabsxyz/features/notifications";
import { StylesProvider } from "@novalabsxyz/styles";
import { tenantContent } from "@novalabsxyz/tenants";

import { Content } from "./content";

export const App = memo(() => (
  <StrictMode>
    <StylesProvider>
      <Provider store={store}>
        <Helmet>
          <title>{tenantContent.name} - Local Dashboard</title>
          <link rel="icon" href={tenantContent.favicon} />
        </Helmet>
        <Layout fixedContainer>
          <Content />
        </Layout>
        <Notifications />
      </Provider>
    </StylesProvider>
  </StrictMode>
));
App.displayName = "App";
