import { memo } from "react";

import type { TypographyProps } from "@novalabsxyz/components/core/mui";
import { Typography } from "@novalabsxyz/components/core/mui";
import { Severity, getColorFromSeverity } from "@novalabsxyz/constants/severity";

export interface SeverityLabelProps {
  text: string | number;
  severity?: Severity;
  typographyProps?: TypographyProps;
}

export const SeverityLabel = memo<SeverityLabelProps>(
  ({ text, severity = Severity.Info, typographyProps = {} }) => (
    <Typography
      {...typographyProps}
      component="span"
      color={`${getColorFromSeverity(severity)}.main`}
    >
      {text}
    </Typography>
  ),
);
SeverityLabel.displayName = "SeverityLabel";
