export interface SpeedTestLatestDTO {
  uploadMbps: number;
  downloadMbps: number;
}

export interface SpeedTestLatest extends SpeedTestLatestDTO {}

export const speedTestLatestQuery = {
  query: () => ({
    url: "/speed-test/latest",
    method: "GET",
  }),
};
