import { memo } from "react";

import { CircularLoader } from "@novalabsxyz/components/core/circular-loader";
import type { TypographyProps } from "@novalabsxyz/components/core/mui";
import { Card, CardContent, Stack, Typography, Divider } from "@novalabsxyz/components/core/mui";
import { ServiceStatusTextLabel } from "@novalabsxyz/components/ui/labels";
import { Severity } from "@novalabsxyz/constants/severity";
import { AvailabilityStatus } from "@novalabsxyz/constants/status";

export interface StatusCardProps {
  title: string;
  subtitle?: string;
  subtitleVariant?: TypographyProps["variant"];
  value?: string;
  valueVariant?: TypographyProps["variant"];
  valueLabel?: string;
  severity?: Severity;
  isLoading?: boolean;
}

export const StatusCard = memo<StatusCardProps>(
  ({
    title,
    subtitle,
    subtitleVariant = "subtitle1",
    value,
    valueVariant = "h3",
    valueLabel,
    severity,
    isLoading = false,
    ...rest
  }) => (
    <Card sx={{ height: 1, position: "relative" }} {...rest}>
      <CardContent sx={{ height: 1 }}>
        <CircularLoader type="itemOverlay" open={isLoading} />
        <Stack sx={{ height: 1 }} spacing={1.5}>
          <Typography sx={{ marginBottom: 0.5 }} variant="h5">
            {title}
          </Typography>
          <Divider />
          <Stack
            sx={{
              flex: 1,
              minHeight: { xs: 60, md: 72 },
            }}
            direction="row"
            spacing={3.5}
            alignItems="center"
          >
            {isLoading ? (
              <Typography variant="h5">Loading...</Typography>
            ) : (
              <>
                {!!subtitle && (
                  <>
                    <Typography sx={{ flex: 1 }} variant={subtitleVariant}>
                      {subtitle}
                    </Typography>
                    <Divider orientation="vertical" flexItem />
                  </>
                )}
                <Stack
                  sx={{
                    ...(!!subtitle && {
                      paddingRight: 3,
                    }),
                  }}
                  alignItems="center"
                >
                  {value !== undefined && valueLabel && (
                    <Typography variant="body2">{valueLabel}</Typography>
                  )}
                  <ServiceStatusTextLabel
                    text={value !== undefined ? value : AvailabilityStatus.NotAvailable}
                    severity={value !== undefined ? severity : Severity.Error}
                    variant={value !== undefined ? valueVariant : "h3"}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  ),
);
StatusCard.displayName = "StatusCard";
