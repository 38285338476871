import { memo, useCallback, useMemo } from "react";

import type { TooltipProps as MuiTooltipProps } from "@mui/material";
import { Box, Tooltip as MuiTooltip, Stack, Typography, IconButton } from "@mui/material";

import type { SvgIconProps } from "@novalabsxyz/icons";
import { QuestionMarkOutlinedIcon, ExclamationMarkTriangleFilledIcon } from "@novalabsxyz/icons";
import { isDictionary, isString } from "@novalabsxyz/utils/lodash-plus";

export interface TooltipProps extends Omit<MuiTooltipProps, "children" | "title"> {
  children?: MuiTooltipProps["children"];
  title?: MuiTooltipProps["title"];
  iconFontSize?: SvgIconProps["fontSize"];
  placeBeforeTarget?: boolean;
  placeAfterTarget?: boolean;
}

interface TooltipTitleProps {
  title?: TooltipProps["title"];
}

const TooltipTitle = memo<TooltipTitleProps>(({ title }) => (
  <Stack spacing={2}>
    <ExclamationMarkTriangleFilledIcon color="primary" />
    {isString(title) ? <Typography variant="body2">{title}</Typography> : title}
  </Stack>
));
TooltipTitle.displayName = "TooltipTitle";

export const Tooltip = memo<TooltipProps>(
  ({
    title,
    iconFontSize,
    placeBeforeTarget = false,
    placeAfterTarget = false,
    children = null,
    ...props
  }) => {
    const renderTooltip = useCallback(
      (target: MuiTooltipProps["children"] | null) => (
        <MuiTooltip
          title={<TooltipTitle title={title} />}
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          arrow
          {...props}
        >
          {target || (
            <IconButton>
              <QuestionMarkOutlinedIcon sx={{ cursor: "pointer" }} fontSize={iconFontSize} />
            </IconButton>
          )}
        </MuiTooltip>
      ),
      [title, iconFontSize, props],
    );

    return useMemo(() => {
      if (
        !title ||
        // Tooltips will not be rendered over disabled elements, so we render just the target element.
        // Handling logic for not rendering tooltips for disabled elements in components that call tooltips in each place is annoying,
        // so we analyzing disabled state here.
        // To still render tooltip for a disabled element, you should add not disabled wrapper element.
        (isDictionary(children?.props) && (children?.props as { disabled?: boolean }).disabled)
      ) {
        return children;
      }

      if (children !== null && (placeBeforeTarget || placeAfterTarget)) {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            {placeBeforeTarget && <Box>{renderTooltip(null)}</Box>}
            {children}
            {placeAfterTarget && <Box>{renderTooltip(null)}</Box>}
          </Stack>
        );
      }

      return renderTooltip(children);
    }, [title, children, placeBeforeTarget, placeAfterTarget, renderTooltip]);
  },
);
Tooltip.displayName = "Tooltip";
