import { ApiClientBase } from "@novalabsxyz/api-client-core";
import { getEnvValue } from "@novalabsxyz/utils/env";
import { yup } from "@novalabsxyz/utils/yup";

export class AgwApiClient extends ApiClientBase {
  constructor() {
    super({
      name: "AGW",
      baseURL: getEnvValue("PUBLIC_AGW_API_BASE_URL", yup.string()),
    });
  }
}
