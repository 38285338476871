import { memo, useCallback, useMemo } from "react";

import { useField, useFormikContext } from "formik";

import { PasswordField } from "@novalabsxyz/components/core";
import type { PasswordFieldProps } from "@novalabsxyz/components/core";

export interface FormPasswordFieldProps
  extends Omit<PasswordFieldProps, "value" | "onChange" | "error"> {}
export const FormPasswordField = memo<FormPasswordFieldProps>(
  ({ name, helperText, disabled, ...props }) => {
    const [{ value }, { touched, error }, { setTouched, setValue }] = useField<string | null>(name);
    const { isSubmitting, validateOnMount } = useFormikContext();

    const handleBlur = useCallback(() => {
      setTouched(true);
    }, [setTouched]);

    return useMemo(
      () => (
        <PasswordField
          {...props}
          name={name}
          value={value}
          onValueChange={setValue}
          onBlur={handleBlur}
          disabled={isSubmitting || disabled}
          error={(validateOnMount || touched) && error !== undefined}
          helperText={((validateOnMount || touched) && error) || helperText}
        />
      ),
      [
        name,
        value,
        error,
        helperText,
        disabled,
        touched,
        isSubmitting,
        validateOnMount,
        props,
        setValue,
        handleBlur,
      ],
    );
  },
);
FormPasswordField.displayName = "FormPasswordField";
