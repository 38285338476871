import { memo, useMemo, useEffect } from "react";

import { Form as FormikForm, useFormikContext } from "formik";
import type { FormikFormProps } from "formik";

export interface FormProps extends FormikFormProps {
  status?: "readOnly";
}

export const Form = memo<FormProps>(
  ({ noValidate = true, autoCorrect = "off", status, children, ...props }) => {
    const { isSubmitting, isValidating, errors, setStatus } = useFormikContext();

    useEffect(() => {
      setStatus(status);
    }, [status, setStatus]);

    // Scroll element with error into view after form submit/validation attempt.
    useEffect(() => {
      const errorKeys = Object.keys(errors);

      if (errorKeys.length > 0 && isSubmitting && !isValidating) {
        const firstErrorKey = errorKeys[0] || "";
        const errorElement =
          document.querySelector(`label[for="${firstErrorKey}"]`) ||
          document.getElementById(firstErrorKey);

        if (errorElement) {
          // TODO: Smooth scroll behavior not working. At least at Mac Chrome.
          errorElement.scrollIntoView({ block: "start", inline: "nearest" });
        }
      }
    }, [isSubmitting, isValidating, errors]);

    return useMemo(
      () => (
        <FormikForm noValidate={noValidate} autoCorrect={autoCorrect} {...props}>
          {children}
        </FormikForm>
      ),
      [noValidate, autoCorrect, props, children],
    );
  },
);
Form.displayName = "Form";
