import { memo } from "react";

import { Divider, Stack, Box } from "@novalabsxyz/components/core";
import { Helmet } from "@novalabsxyz/components/seo";
import { tenantContent } from "@novalabsxyz/tenants";

import { MinerPanelPrimary } from "./miner-panel-primary";
import { MinerPanelSync } from "./miner-panel-sync";
import { MinerStateManagement } from "./miner-state-management";
import { MinerSupport } from "./miner-support";

export const MinerDashboardHeaderEnd = MinerSupport;

export const MinerDashboardPage = memo(() => {
  return (
    <>
      <Helmet>
        <title>{tenantContent.name} - Miner Dashboard</title>
      </Helmet>
      <Box
        sx={{
          paddingY: {
            xs: 4,
            md: 5,
          },
        }}
      >
        <Stack spacing={4}>
          <MinerPanelPrimary />
          <Divider />
          <MinerPanelSync />
          <Divider />
          <MinerStateManagement />
        </Stack>
      </Box>
    </>
  );
});
MinerDashboardPage.displayName = "MinerDashboardPage";
