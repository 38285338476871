export interface SoftwareVersionDTO {
  version: string;
}

export interface SoftwareVersion {
  version: string;
}

export const softwareVersionQuery = {
  query: () => ({
    url: "/software-version",
    method: "GET",
  }),
};
