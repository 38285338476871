import type { Severity } from "@novalabsxyz/constants/severity";
import type { AvailabilityStatus } from "@novalabsxyz/constants/status";
import { boolToAvailabilityStatus, availabilityToSeverity } from "@novalabsxyz/constants/status";

type InternetAccessDTO = {
  isAccessible: boolean;
};

export interface InternetAccess {
  status: AvailabilityStatus;
  severity: Severity;
}

const transformResponse = (data: InternetAccessDTO): InternetAccess => {
  const status = boolToAvailabilityStatus(data.isAccessible);
  return {
    status,
    severity: availabilityToSeverity(status),
  };
};

export const internetAccessQuery = {
  query: () => ({
    url: "/internet-access",
    method: "GET",
  }),
  transformResponse,
};
