import { memo } from "react";

import { SvgIcon } from "@mui/material";
import type { SvgIconProps } from "@mui/material";

import { getColorInSxProp, getContrastColorInSxProp } from "@novalabsxyz/styles/utils";

import { ReactComponent as ApproveFilledSvg } from "./svg/approve-filled.svg";
import { ReactComponent as ExclamationMarkTriangleFilledSvg } from "./svg/exclamation-mark-triangle-filled.svg";
import { ReactComponent as RejectFilledSvg } from "./svg/reject-filled.svg";

export const ApproveFilledIcon = memo<SvgIconProps>((props) => (
  <SvgIcon
    sx={{
      "& [class$='contrast']": {
        fill: getContrastColorInSxProp(props.color),
      },
      ...props.sx,
    }}
    component={ApproveFilledSvg}
    {...props}
  />
));
ApproveFilledIcon.displayName = "ApproveFilledIcon";

export const ExclamationMarkTriangleFilledIcon = memo<SvgIconProps>((props) => (
  <SvgIcon
    sx={{
      "& [class$='contrast']": {
        fill: getContrastColorInSxProp(props.color),
      },
      ...props.sx,
    }}
    component={ExclamationMarkTriangleFilledSvg}
    {...props}
  />
));
ExclamationMarkTriangleFilledIcon.displayName = "ExclamationMarkTriangleFilledIcon";
export const WarningIcon = ExclamationMarkTriangleFilledIcon;

export const RejectFilledIcon = memo<SvgIconProps>((props) => (
  <SvgIcon
    sx={{
      "& path": {
        fill: getColorInSxProp(props.color),
      },
      "& [class$='contrast']": {
        fill: getContrastColorInSxProp(props.color),
      },
    }}
    component={RejectFilledSvg}
    {...props}
  />
));
RejectFilledIcon.displayName = "RejectFilledIcon";
