import type { ReactNode } from "react";
import { memo } from "react";

import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./theme";

export interface StylesProviderProps {
  children: ReactNode;
}

export const StylesProvider = memo<StylesProviderProps>(({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles
      styles={{
        body: {
          overflow: "hidden",
        },

        ul: {
          paddingInlineStart: 0,
          marginBlockStart: 0,
          marginBlockEnd: 0,
        },
      }}
    />
    {children}
  </ThemeProvider>
));
StylesProvider.displayName = "StylesProvider";
