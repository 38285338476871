import { memo } from "react";

import { Box } from "@novalabsxyz/components/core/mui";
import { SeverityLabel } from "@novalabsxyz/components/ui/labels/severity";
import { Severity, getColorFromSeverity } from "@novalabsxyz/constants/severity";

export interface ServiceStatusContainedLabelProps {
  text: string | number;
  severity?: Severity;
}

export const ServiceStatusContainedLabel = memo<ServiceStatusContainedLabelProps>(
  ({ text, severity = Severity.Info }) => (
    <Box
      sx={{
        backgroundColor: `${getColorFromSeverity(severity)}.bleached`,
        paddingX: 2.5,
        paddingY: 2,
        minWidth: {
          xs: 130,
          md: 170,
        },
        borderRadius: 1,
        minHeight: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SeverityLabel
        severity={severity}
        text={text}
        typographyProps={{
          variant: "body2",
          textTransform: "uppercase",
          fontWeight: 800,
        }}
      />
    </Box>
  ),
);
ServiceStatusContainedLabel.displayName = "ServiceStatusContainedLabel";
