import { isArray, isEqual, isString, pick } from "lodash";

import type { SortOptions } from "@novalabsxyz/constants/sorting";
import { SortOrder } from "@novalabsxyz/constants/sorting";

export {
  cloneDeep,
  compact,
  defaultsDeep,
  isArray,
  isEqual,
  isNaN,
  isNumber,
  isString,
  lowerCase,
  merge,
  omit,
  pick,
  random,
  round,
  snakeCase,
  startCase,
} from "lodash";

export const isDictionary = (value: unknown): value is Record<string, unknown> =>
  value != null && typeof value === "object" && !isArray(value);

export const parseBoolean = ({ value }: { value: string | boolean }): boolean =>
  [true, "true", "True"].includes(value);

export const toArray = <T>({ value }: { value: T }): T[] =>
  (Array.isArray(value) ? value : [value]).filter((item) => !!item) as T[];

export const includesObject = <Superset extends object, Set extends object>(
  superset: Superset,
  set: Set,
): boolean => {
  const keysToCompare = Object.keys(set);
  return isEqual(
    pick(superset, keysToCompare),
    // Pick from set is done to avoid problems with comparisons when set is a class instance.
    pick(set, keysToCompare),
  );
};

export const compactObject = <T extends object>(source: T): T =>
  JSON.parse(JSON.stringify(source)) as T;

export interface ChangeKeysOptions {
  /**
   * List of paths (ex. 'key.nestedKey') under which case change will be skipped.
   */
  ignorePaths?: string[];
}

type TransformKeyFunction = (key: string) => string;
export const changeKeys = <T>(
  source: T,
  transform: TransformKeyFunction,
  options: ChangeKeysOptions = {},
  path = "",
): T => {
  const { ignorePaths = [] } = options;
  let result;

  if (isDictionary(source)) {
    result = Object.fromEntries(
      Object.entries(source).map(([key, value]) => {
        const keyPath = path ? `${path}.${key}` : key;
        return [
          transform(key),
          ignorePaths.includes(keyPath) ? value : changeKeys(value, transform, options, keyPath),
        ];
      }),
    );
  } else if (isArray(source)) {
    result = source.map((item) => changeKeys(item as unknown, transform, options, path));
  } else {
    result = source;
  }

  return result as T;
};

export const getSortObjectsFunction =
  <T extends Record<string, unknown>>({ sortField, sortOrder }: SortOptions) =>
  (currentRecord: T, nextRecord: T): number => {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    const currentItemRaw = currentRecord[sortField];
    const currentItem = isString(currentItemRaw) ? currentItemRaw.toLowerCase() : currentItemRaw;
    const nextItemRaw = nextRecord[sortField];
    const nextItem = isString(nextItemRaw) ? nextItemRaw.toLowerCase() : nextItemRaw;
    /* eslint-enable @typescript-eslint/no-unsafe-assignment */

    if (currentItem === undefined || currentItem === null) {
      return 1;
    }
    if (nextItem === undefined || nextItem === null) {
      return -1;
    }
    if (currentItem === nextItem) {
      return 0;
    }
    if (currentItem > nextItem) {
      return sortOrder === SortOrder.Descending ? -1 : 1;
    }

    return sortOrder === SortOrder.Descending ? 1 : -1;
  };
