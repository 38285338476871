import { Severity } from "@novalabsxyz/constants/severity";

export enum ConnectionStatus {
  None = "none",
  Connected = "connected",
  Disconnected = "disconnected",
}

export const boolToConnectionStatus = (value?: boolean): ConnectionStatus => {
  switch (value) {
    case true:
      return ConnectionStatus.Connected;
    case false:
      return ConnectionStatus.Disconnected;
    case undefined:
    default:
      return ConnectionStatus.None;
  }
};

export const connectionToSeverity = (
  status: ConnectionStatus = ConnectionStatus.None,
): Severity => {
  const statusMap = {
    [ConnectionStatus.None]: Severity.Info,
    [ConnectionStatus.Connected]: Severity.Success,
    [ConnectionStatus.Disconnected]: Severity.Error,
  };

  return statusMap[status];
};
