import type { ReactNode } from "react";
import { memo } from "react";

import { Box, Stack, IconButton, Typography } from "@mui/material";

import { CloseIcon } from "@novalabsxyz/icons";

export interface DialogHeaderProps {
  onClose?: () => void;
  children: ReactNode;
}

export const DialogHeader = memo<DialogHeaderProps>(({ children, onClose }) => (
  <Box
    sx={{
      paddingX: 2.5,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "background.default",
    }}
  >
    <Stack
      sx={{
        paddingY: 2,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
    >
      <Typography fontWeight={600}>{children}</Typography>
      {onClose ? (
        <IconButton sx={{ alignSelf: "flex-start", color: "text.primary" }} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </Stack>
  </Box>
));
DialogHeader.displayName = "DialogHeader";
