import { useCallback, memo, useMemo } from "react";

import type { RadioInfo } from "@novalabsxyz/agw-api-client";
import { Box, Stack, Divider, Grid, Typography } from "@novalabsxyz/components/core";
import type { PrepareFormValues } from "@novalabsxyz/components/form";
import {
  Formik,
  Form,
  FormFilesDropzoneField,
  FormTextField,
  FormSelectField,
  FormCheckboxField,
  FormSubmitButton,
  FormNumberField,
} from "@novalabsxyz/components/form";
import { MeasurementUnit } from "@novalabsxyz/constants/measurement";
import type {
  RadioRegistrationData,
  RadioRegistrationPrerequisites,
} from "@novalabsxyz/cpi-api-client";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import { useApiRequest } from "@novalabsxyz/features/api";
import { tenantContent } from "@novalabsxyz/tenants";
import { assert } from "@novalabsxyz/utils/assert";
import { omit } from "@novalabsxyz/utils/lodash-plus";
import { yup } from "@novalabsxyz/utils/yup";

interface ValidatedFormValues
  extends Omit<
    RadioRegistrationData,
    | "gatewaySerialNumber"
    | "gatewayVendor"
    | "manufacturer"
    | "productClass"
    | "radioSerialNumber"
    | "cbsdSerialNumber"
    | "address"
    | "isAntennaExternal"
  > {
  street: string;
  city: string;
  state: string;
  zipCode: number;
  agreement: boolean;
}
type FormValues = PrepareFormValues<ValidatedFormValues>;

const OTHER_ANTENNA_OPTION_VALUE = 0;

export interface RadioRegistrationFormProps {
  gatewaySerialNumber: string;
  gatewayVendor: string;
  radioInfo: RadioInfo;
  prerequisites: RadioRegistrationPrerequisites;
  onSubmitted: (registrationId: number) => void;
}
export const RadioRegistrationForm = memo<RadioRegistrationFormProps>(
  ({
    gatewaySerialNumber,
    gatewayVendor,
    radioInfo: {
      manufacturer,
      productClass,
      serial: radioSerialNumber,
      latitude,
      longitude,
      isAntennaExternal,
      migration,
    },
    prerequisites: { antennaModels },
    onSubmitted,
  }) => {
    const { apiRequest } = useApiRequest();

    const initialValues = useMemo(
      () => ({
        email: "",
        street: "",
        city: "",
        state: "",
        zipCode: null,
        latitude,
        longitude,
        heightValue: null,
        heightUnit: MeasurementUnit.Foot,
        azimuth: null,
        elevation: null,
        heightImages: [],
        azimuthImages: [],
        elevationImages: [],
        antennaModelId: isAntennaExternal ? null : antennaModels[0]?.id || null,
        antennaPartNumber: null,
        modelPlateImages: [],
        overallImages: [],
        agreement: false,
      }),
      [latitude, longitude, isAntennaExternal, antennaModels],
    );

    const validationSchema = useMemo(
      () =>
        yup.object().shape({
          email: yup.string().required().email(),
          street: yup.string().required(),
          city: yup.string().required(),
          state: yup.string().required(),
          zipCode: yup.number().integer().positive().required().nullable(),
          latitude: yup.number().required().nullable(),
          longitude: yup.number().required().nullable(),
          heightValue: yup.number().required().positive().nullable(),
          heightUnit: yup.string().required().oneOf(Object.values(MeasurementUnit)),
          azimuth: yup.number().required().min(0).lessThan(360).nullable(),
          elevation: yup.number().required().min(-30).max(30).nullable(),
          heightImages: yup.array().min(1).max(4),
          azimuthImages: yup.array().min(1).max(1),
          elevationImages: yup.array().min(1).max(1),
          antennaModelId: yup
            .number()
            .nullable()
            .label("Antenna Model")
            .when({
              is: () => isAntennaExternal,
              then: (shape) => shape.required().integer().min(0),
            }),
          antennaPartNumber: yup
            .string()
            .nullable()
            .when("antennaModelId", {
              is: OTHER_ANTENNA_OPTION_VALUE,
              then: (shape) => shape.required(),
            }),
          modelPlateImages: yup.array().when({
            is: () => isAntennaExternal,
            then: (shape) => shape.min(1).max(1),
            otherwise: (shape) => shape.min(0).max(0),
          }),
          overallImages: yup.array().when({
            is: () => isAntennaExternal,
            then: (shape) => shape.min(1).max(1),
            otherwise: (shape) => shape.min(0).max(0),
          }),
          agreement: yup.boolean().oneOf([true], "You must certify the provided information"),
        }),
      [isAntennaExternal],
    );

    const handleFormSubmit = useCallback(
      async (values: FormValues) => {
        assert(manufacturer, "Manufacturer is not specified.");
        assert(productClass, "Product class is not specified.");
        assert(!migration.consentRequired, "Migration consent is required bit not given.");
        const validatedValues = validationSchema.cast(values) as ValidatedFormValues;

        const address = [
          validatedValues.street,
          validatedValues.city,
          validatedValues.state,
          validatedValues.zipCode,
        ]
          .map((item) => item.toString().trim())
          .join(" ");

        try {
          const { id } = await apiRequest(cpiApiClient.postRadioRegistration, [
            {
              gatewaySerialNumber,
              gatewayVendor,
              manufacturer,
              productClass,
              radioSerialNumber,
              address,
              isAntennaExternal,
              ...(migration.consentGiven ? { cbsdSerialNumber: `${radioSerialNumber}H` } : {}),
              ...(validatedValues.antennaModelId === OTHER_ANTENNA_OPTION_VALUE
                ? {
                    antennaModelId: null,
                    antennaPartNumber: validatedValues.antennaPartNumber,
                  }
                : { antennaModelId: validatedValues.antennaModelId, antennaPartNumber: null }),
              ...omit(validatedValues, [
                "street",
                "city",
                "state",
                "zipCode",
                "antennaModelId",
                "antennaPartNumber",
                "agreement",
              ]),
            },
          ]);

          onSubmitted(id);
        } catch (err) {
          return;
        }
      },
      [
        gatewaySerialNumber,
        gatewayVendor,
        manufacturer,
        productClass,
        radioSerialNumber,
        isAntennaExternal,
        migration,
        apiRequest,
        validationSchema,
        onSubmitted,
      ],
    );

    return useMemo(
      () => (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values }) => (
            <Form>
              <Stack spacing={5}>
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormTextField name="email" type="email" />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">Address where the radio is installed</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField name="street" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormTextField name="city" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormTextField name="state" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormNumberField name="zipCode" integer positive />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">Antenna Installation Parameters</Typography>
                    </Grid>
                    <Grid item sm={9} xs={8}>
                      <FormNumberField name="heightValue" />
                    </Grid>
                    <Grid item sm={3} xs={4}>
                      <FormSelectField
                        name="heightUnit"
                        label="Units"
                        options={Object.values(MeasurementUnit).map((unit) => ({ value: unit }))}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormFilesDropzoneField
                        name="heightImages"
                        multiple
                        description="Screenshots are not accepted"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumberField name="azimuth" label="Azimuth angle in degrees" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormFilesDropzoneField
                        name="azimuthImages"
                        label="Azimuth Image"
                        description="Screenshots are not accepted"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumberField name="elevation" label="Elevation angle in degrees" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormFilesDropzoneField
                        name="elevationImages"
                        label="Elevation Image"
                        description="Screenshots are not accepted"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormTextField name="latitude" disabled />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormTextField name="longitude" disabled />
                    </Grid>
                    {isAntennaExternal && (
                      <>
                        <Grid item xs={12}>
                          <FormSelectField
                            name="antennaModelId"
                            label="Antenna Model"
                            options={[
                              ...antennaModels.map(
                                ({
                                  id,
                                  manufacturer: antennaManufacturer,
                                  antennaName,
                                  model,
                                }) => ({
                                  value: id,
                                  label:
                                    [
                                      antennaManufacturer,
                                      antennaName,
                                      ...(model !== antennaName ? [model] : []),
                                    ].join(", ") || id,
                                }),
                              ),
                              { value: OTHER_ANTENNA_OPTION_VALUE, label: "Other" },
                            ]}
                            optionForUndefined={{ value: "--" }}
                            defaultValue="--"
                          />
                        </Grid>
                        {values.antennaModelId === OTHER_ANTENNA_OPTION_VALUE ? (
                          <Grid item xs={12}>
                            <Stack spacing={2}>
                              <Typography>
                                Please fill up the input below with your antenna part number.
                                <br />
                                Registration review may take a bit longer since we must add your
                                antenna to our known antennas list.
                              </Typography>
                              <FormTextField name="antennaPartNumber" />
                            </Stack>
                          </Grid>
                        ) : null}
                        <Grid item xs={12}>
                          <FormFilesDropzoneField
                            name="modelPlateImages"
                            label="Antenna Product Badge Image"
                            description="Screenshots are not accepted"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormFilesDropzoneField
                            name="overallImages"
                            label="Overall Antenna Image"
                            description="Screenshots are not accepted"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormCheckboxField
                        name="agreement"
                        label={`I hereby certify that, to the best of my knowledge, the provided information is true and accurate. Furthermore, I will update ${tenantContent.name} within 24 hour with any changes to the information above.`}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FormSubmitButton
                    disabled={!manufacturer || !productClass || migration.consentRequired}
                  >
                    Submit
                  </FormSubmitButton>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      ),
      [
        initialValues,
        validationSchema,
        manufacturer,
        productClass,
        isAntennaExternal,
        antennaModels,
        migration,
        handleFormSubmit,
      ],
    );
  },
);
RadioRegistrationForm.displayName = "RadioRegistrationForm";
