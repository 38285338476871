import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { GpsStatus, SpectrumStatus } from "@novalabsxyz/agw-api-client";
import type { RadioInfo } from "@novalabsxyz/agw-api-client";
import { Link } from "@novalabsxyz/components/core";
import { ConnectionStatus, OnOffStatus } from "@novalabsxyz/constants/status";
import { notificationsActions, stringifyNotification } from "@novalabsxyz/features/notifications";

enum ExceptionName {
  UnexpectedError,
  GpsTrying,
  SpectrumTryingCoreNotConnected,
  SpectrumConnectedCoreNotConnected,
  SpectrumConnectedCoreConnected,
  CoreDisconnectedAirOff,
}

interface Exception {
  title: string;
  message: string | string[];
}

const exceptions: {
  [key in ExceptionName]: Exception;
} = {
  [ExceptionName.UnexpectedError]: {
    title: "Unexpected radio state",
    message: "Some functionality might be disabled",
  },
  [ExceptionName.GpsTrying]: {
    title: "GPS status “trying” after more than 15 min of uptime",
    message: [
      "Check that GPS antenna connector is seated straight and firm finger tight",
      "Check that GPS antenna is near a window",
    ],
  },
  [ExceptionName.SpectrumTryingCoreNotConnected]: {
    title: "SAS status “trying” for more than 15min after boot",
    message: [
      "Factory reset the radio (paper clip in reset hole, hold for >15 sec) and allow it to try again.",
      "If no change, download support logs, contact support.",
    ],
  },
  [ExceptionName.SpectrumConnectedCoreNotConnected]: {
    title: "Spectrum Access status “Connected” for more than 15min after boot",
    message:
      "Do not reboot, do not reset, wait 24hrs, if no change, download support logs, contact support",
  },
  [ExceptionName.SpectrumConnectedCoreConnected]: {
    title: "Spectrum Access status went back to “Connected” after it had been working.",
    message: stringifyNotification(
      <>
        Wait and don&apos;t reboot. Suspension can last from hours to days for DPA Event. Contact
        support if you have been suspended for more than 48hrs. This is more prevalent if your
        location is within 150km of the&nbsp;
        <Link
          color="error"
          target="_blank"
          rel="noreferrer"
          href="https://federatedwireless.com/wp-content/uploads/2018/09/ESC-Network-5.jpg?_ga=2.22067519.505863676.1638244590-1556127447.1638244590"
        >
          DPA Regions
        </Link>
        .
      </>,
    ),
  },
  [ExceptionName.CoreDisconnectedAirOff]: {
    title: "Cannot connect to MME/S1",
    message: [
      "Reboot AGW & reboot radio, check again after 15 minutes.",
      "Factory reset the radio (paper clip in reset hole, hold for >15 sec) and allow it to try again.",
      "If neither resolves, download support logs, contact support.",
    ],
  },
};

export const useRadioInfoException = (radioInfo: RadioInfo): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!radioInfo) {
      return;
    }

    let exception: Exception | undefined;

    if (!radioInfo.productClass || !radioInfo.manufacturer) {
      exception = exceptions[ExceptionName.UnexpectedError];
    } else if (radioInfo.gpsStatus === GpsStatus.Trying) {
      exception = exceptions[ExceptionName.GpsTrying];
    } else if (
      radioInfo.spectrumStatus === SpectrumStatus.Trying &&
      radioInfo.coreConnectionStatus === ConnectionStatus.None
    ) {
      exception = exceptions[ExceptionName.SpectrumTryingCoreNotConnected];
    } else if (
      radioInfo.spectrumStatus === SpectrumStatus.Connected &&
      radioInfo.coreConnectionStatus === ConnectionStatus.None
    ) {
      exception = exceptions[ExceptionName.SpectrumConnectedCoreNotConnected];
    } else if (
      radioInfo.spectrumStatus === SpectrumStatus.Connected &&
      radioInfo.coreConnectionStatus === ConnectionStatus.Connected
    ) {
      exception = exceptions[ExceptionName.SpectrumConnectedCoreConnected];
    } else if (
      radioInfo.coreConnectionStatus === ConnectionStatus.Disconnected &&
      radioInfo.airStatus === OnOffStatus.Off
    ) {
      exception = exceptions[ExceptionName.CoreDisconnectedAirOff];
    }

    if (exception) {
      dispatch(notificationsActions.openError(exception.message, exception.title));
    }

    return () => {
      dispatch(notificationsActions.closeAll());
    };
  }, [radioInfo, dispatch]);
};
