import { memo } from "react";

import type { TypographyProps } from "@novalabsxyz/components/core";
import { SeverityLabel } from "@novalabsxyz/components/ui/labels/severity";
import { Severity } from "@novalabsxyz/constants/severity";

export interface ServiceStatusTextLabelProps {
  text: string | number;
  severity?: Severity;
  variant?: TypographyProps["variant"];
}

export const ServiceStatusTextLabel = memo<ServiceStatusTextLabelProps>(
  ({ text, severity = Severity.Info, variant = "h3" }) => (
    <SeverityLabel
      severity={severity}
      text={text}
      typographyProps={{
        variant: variant,
        textTransform: "uppercase",
        fontWeight: 900,
      }}
    />
  ),
);
ServiceStatusTextLabel.displayName = "ServiceStatusTextLabel";
