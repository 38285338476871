import { memo } from "react";

import { SvgIcon } from "@mui/material";
import type { SvgIconProps } from "@mui/material";

import { ReactComponent as ApproveSvg } from "./svg/approve.svg";
import { ReactComponent as BackSvg } from "./svg/back.svg";
import { ReactComponent as ContentCopySvg } from "./svg/content-copy.svg";
import { ReactComponent as DeleteFileSvg } from "./svg/delete-file.svg";
import { ReactComponent as DownloadSvg } from "./svg/download.svg";
import { ReactComponent as ExpandLessSvg } from "./svg/expand-less.svg";
import { ReactComponent as ExpandMoreSvg } from "./svg/expand-more.svg";
import { ReactComponent as FullSizeSvg } from "./svg/full-size.svg";
import { ReactComponent as LightningSvg } from "./svg/lightning.svg";
import { ReactComponent as OpenInNewSvg } from "./svg/open-in-new.svg";
import { ReactComponent as OpenMenuSvg } from "./svg/open-menu.svg";
import { ReactComponent as RejectSvg } from "./svg/reject.svg";
import { ReactComponent as RenewSvg } from "./svg/renew.svg";
import { ReactComponent as SearchSvg } from "./svg/search.svg";
import { ReactComponent as SignOutSvg } from "./svg/sign-out.svg";
import { ReactComponent as SimCardSvg } from "./svg/sim-card.svg";
import { ReactComponent as SortOrderSvg } from "./svg/sort-order.svg";
import { ReactComponent as UploadSvg } from "./svg/upload.svg";
import { ReactComponent as VisibilityOffSvg } from "./svg/visibility-off.svg";
import { ReactComponent as VisibilityOnSvg } from "./svg/visibility-on.svg";

export const ApproveIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={ApproveSvg} {...props} />
));
ApproveIcon.displayName = "ApproveIcon";
export const CheckedIcon = ApproveIcon;
export const YesIcon = ApproveIcon;

export const BackIcon = memo<SvgIconProps>((props) => <SvgIcon component={BackSvg} {...props} />);
BackIcon.displayName = "BackIcon";

export const ContentCopyIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={ContentCopySvg} {...props} />
));
ContentCopyIcon.displayName = "ContentCopyIcon";

export const DeleteFileIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={DeleteFileSvg} {...props} />
));
DeleteFileIcon.displayName = "DeleteFileIcon";

export const DownloadIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={DownloadSvg} {...props} />
));
DownloadIcon.displayName = "DownloadIcon";

export const FullSizeIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={FullSizeSvg} {...props} />
));
FullSizeIcon.displayName = "FullSizeIcon";

export const LightningIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={LightningSvg} {...props} />
));
LightningIcon.displayName = "LightningIcon";
export const GatewayIcon = LightningIcon;

export const OpenInNewIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={OpenInNewSvg} {...props} />
));
OpenInNewIcon.displayName = "OpenInNewIcon";

export const OpenMenuIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={OpenMenuSvg} {...props} />
));
OpenMenuIcon.displayName = "OpenMenuIcon";

export const ExpandLessIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={ExpandLessSvg} {...props} />
));
ExpandLessIcon.displayName = "ExpandLessIcon";

export const ExpandMoreIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={ExpandMoreSvg} {...props} />
));
ExpandMoreIcon.displayName = "ExpandMoreIcon";

export const RejectIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={RejectSvg} {...props} />
));
RejectIcon.displayName = "RejectIcon";
export const CloseIcon = RejectIcon;
export const CloseMenuIcon = RejectIcon;
export const NoIcon = RejectIcon;

export const RenewIcon = memo<SvgIconProps>((props) => <SvgIcon component={RenewSvg} {...props} />);
RenewIcon.displayName = "RenewIcon";
export const PendingIcon = RenewIcon;

export const SearchIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={SearchSvg} {...props} />
));
SearchIcon.displayName = "SearchIcon";

export const SignOutIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={SignOutSvg} {...props} />
));
SignOutIcon.displayName = "SignOutIcon";

export const SimCardIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={SimCardSvg} {...props} />
));
SimCardIcon.displayName = "SimCardIcon";

export const SortOrderIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={SortOrderSvg} {...props} />
));
SortOrderIcon.displayName = "SortOrderIcon";

export const UploadIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={UploadSvg} {...props} />
));
UploadIcon.displayName = "UploadIcon";

export const VisibilityOffIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={VisibilityOffSvg} {...props} />
));
VisibilityOffIcon.displayName = "VisibilityOffIcon";

export const VisibilityOnIcon = memo<SvgIconProps>((props) => (
  <SvgIcon component={VisibilityOnSvg} {...props} />
));
VisibilityOnIcon.displayName = "VisibilityOnIcon";
