import { memo } from "react";

import { useFormikContext } from "formik";

import { Button } from "@novalabsxyz/components/core";
import type { ButtonProps } from "@novalabsxyz/components/core";

export interface FormButtonProps extends ButtonProps {
  allowClean?: boolean;
}

export const FormButton = memo<FormButtonProps>(({ disabled, allowClean, ...props }) => {
  const { isSubmitting, dirty } = useFormikContext();

  return <Button {...props} disabled={isSubmitting || (!allowClean && !dirty) || disabled} />;
});
FormButton.displayName = "FormButton";

export const FormSubmitButton = memo<Omit<FormButtonProps, "type">>((props) => (
  <FormButton {...props} type="submit" />
));
FormSubmitButton.displayName = "FormSubmitButton";
