import { memo, useCallback } from "react";

import { Box, Grid, Typography, Button } from "@novalabsxyz/components/core";

interface SelectRadioButtonProps {
  selected?: boolean;
  index: number;
  onSelect: (radioIndex: number) => void;
}

const SelectRadioButton = memo<SelectRadioButtonProps>(({ selected, onSelect, index }) => {
  const handleSelect = useCallback(() => {
    onSelect(index);
  }, [index, onSelect]);

  return (
    <Button
      sx={{
        textTransform: "none",
        fontSize: "body1.fontSize",
        color: "text.primary",
        backgroundColor: "background.paper",

        "&:hover": {
          color: "success.main",
          backgroundColor: "success.bleached",
        },

        ...(selected && {
          color: "success.main",
          backgroundColor: "success.bleached",
        }),
      }}
      size="small"
      color="secondary"
      onClick={handleSelect}
    >
      Radio #{index}
    </Button>
  );
});
SelectRadioButton.displayName = "SelectRadioButton";

export interface RadioSelectProps {
  radioCount: number;
  selectedRadioIndex: number;
  onSelect: (radioIndex: number) => void;
}

export const RadioSelect = memo<RadioSelectProps>(
  ({ onSelect, radioCount, selectedRadioIndex }) => (
    <Box
      sx={{
        backgroundColor: "info.bleached",
        paddingY: 2,
        paddingX: 2.5,
        minHeight: 80,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container alignItems="center" spacing={2.5}>
        <Grid item>
          <Typography variant="h5">Choose Radio:</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {[...Array(radioCount).keys()]
              .map((index) => index + 1)
              .map((radioIndex) => (
                <Grid item key={radioIndex}>
                  <SelectRadioButton
                    selected={radioIndex === selectedRadioIndex}
                    onSelect={onSelect}
                    index={radioIndex}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ),
);
RadioSelect.displayName = "RadioSelect";
