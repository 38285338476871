import { memo } from "react";

import type { SvgIconProps } from "@mui/material";

import { YesIcon, NoIcon } from "@novalabsxyz/icons/regular";

export interface YesNoIconProps extends SvgIconProps {
  value: boolean;
}

export const YesNoIcon = memo<YesNoIconProps>(({ value, ...props }) =>
  value ? <YesIcon color="success" {...props} /> : <NoIcon color="error" {...props} />,
);
YesNoIcon.displayName = "YesNoIcon";
