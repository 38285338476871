import type { SyntheticEvent, ReactElement } from "react";
import { memo, useState, useCallback, useMemo } from "react";

import { TabContext, TabPanel, TabList } from "@mui/lab";
import { Tab, Stack, Grid } from "@mui/material";

export interface TabsProps {
  tabs: {
    [key: string]: ReactElement;
  };
  defaultTab: string;
  headerEnd?: {
    [key: string]: ReactElement;
  };
  fullHeight?: boolean;
}

export const Tabs = memo<TabsProps>(({ tabs, defaultTab, headerEnd = {}, fullHeight = false }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleActiveTabChange = useCallback((_event: SyntheticEvent, newActiveTab: string) => {
    setActiveTab(newActiveTab);
  }, []);

  return useMemo(
    () => (
      <Stack
        sx={{
          width: 1,
          ...(fullHeight && {
            height: 1,
          }),
        }}
      >
        <TabContext value={activeTab}>
          <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
            <Grid item>
              <TabList onChange={handleActiveTabChange}>
                {Object.keys(tabs).map((tabName) => (
                  <Tab key={tabName} value={tabName} label={tabName} />
                ))}
              </TabList>
            </Grid>
            {headerEnd[activeTab] && <Grid item>{headerEnd[activeTab]}</Grid>}
          </Grid>
          {Object.keys(tabs).map((tabName) => (
            <TabPanel
              sx={{
                padding: 0,
                flex: 1,
                position: "relative",
              }}
              key={tabName}
              value={tabName}
            >
              {tabs[tabName]}
            </TabPanel>
          ))}
        </TabContext>
      </Stack>
    ),
    [tabs, activeTab, headerEnd, fullHeight, handleActiveTabChange],
  );
});
Tabs.displayName = "Tabs";
