export const transformToBase64 = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (): void => {
      resolve(reader.result as string);
    };

    reader.onerror = (err): void => {
      const message = "File encoding to base64 failed.";
      // TODO: Add logger.
      // eslint-disable-next-line no-console
      console.error(message, err);
      reject(message);
    };

    reader.readAsDataURL(file);
  });

export const transformManyToBase64 = async (files: File[]): Promise<string[]> =>
  Promise.all(files.map((file) => transformToBase64(file)));
