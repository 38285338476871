export interface SerialId {
  serialId: string;
}

export const serialIdQuery = {
  query: () => ({
    url: "/serial-id",
    method: "GET",
  }),
};
