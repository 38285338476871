import type { MouseEvent } from "react";
import { memo, useCallback, useMemo } from "react";

import { IconButton } from "@mui/material";

import { useBoolean } from "@novalabsxyz/hooks";
import { VisibilityOnIcon, VisibilityOffIcon } from "@novalabsxyz/icons";

import { TextField } from "./text-field";
import type { TextFieldProps } from "./text-field";

export interface PasswordFieldProps
  extends Omit<TextFieldProps, "type" | "multiline" | "minRows" | "maxRows" | "rows"> {}
export const PasswordField = memo<PasswordFieldProps>((props) => {
  const { value: isPasswordVisible, setTrue: showPassword, setFalse: hidePassword } = useBoolean();

  const preventDefaultEvent = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return useMemo(
    () => (
      <TextField
        type={isPasswordVisible ? "text" : "password"}
        endAdornment={
          <IconButton
            onClick={isPasswordVisible ? hidePassword : showPassword}
            onMouseDown={preventDefaultEvent}
            edge="end"
          >
            {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
          </IconButton>
        }
        {...props}
      />
    ),
    [isPasswordVisible, hidePassword, showPassword, preventDefaultEvent, props],
  );
});
PasswordField.displayName = "PasswordField";
