import type { Breakpoint } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

export type { Breakpoint };

export interface UseResponsiveOptions {
  from?: number | Breakpoint;
  to?: number | Breakpoint;
}

export const useResponsive = ({
  from = 0,
  to = Number.MAX_SAFE_INTEGER,
}: UseResponsiveOptions): boolean => {
  const { breakpoints } = useTheme();
  const isMoreOrEqualsFrom = useMediaQuery(breakpoints.up(from));
  const isLessOrEqualsTo = useMediaQuery(breakpoints.down(to));

  return isMoreOrEqualsFrom && isLessOrEqualsTo;
};
