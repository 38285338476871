// eslint-disable-next-line import/no-unassigned-import
import "reflect-metadata";

import { createRoot } from "react-dom/client";

import { App } from "./components/app";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<App />);
