import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { GpsStatus, SpectrumStatus } from "@novalabsxyz/agw-api-client";
import type { RadioInfo } from "@novalabsxyz/agw-api-client";
import { Config } from "@novalabsxyz/agw-client/config";
import { radioProductClassToModel } from "@novalabsxyz/constants/radio";
import { ConnectionStatus, OnOffStatus } from "@novalabsxyz/constants/status";

enum SasStatus {
  Unknown = "UNKNOWN",
  Idle = "IDLE",
  Trying = "TRYING",
  Connected = "CONNECTED",
  Success = "SUCCESS",
  Disconnected = "DISCONNECTED",
  Failure = "FAILURE",
}

enum DpResponseCode {
  Success = 0,
  Version = 100,
  Blacklisted = 101,
  MissingParam = 102,
  InvalidValue = 103,
  CertError = 104,
  Deregister = 105,
  RegPending = 200,
  GroupError = 201,
  UnsupportedSpectrum = 300,
  Interference = 400,
  GrantConflict = 401,
  TerminatedGrant = 500,
  SuspendedGrant = 501,
  UnsyncOpParam = 502,
}

enum EnbStatus {
  Unknown = "UNKNOWN",
  Idle = "IDLE",
  Trying = "TRYING",
  Connected = "CONNECTED",
  Success = "SUCCESS",
  LocationFailure = "LOCATIONFAILURE",
  Disconnected = "DISCONNECTED",
  Failure = "FAILURE",
}

enum MmeConnectedStatus {
  None,
  Connected,
  Disconnected,
}

const mmeConnectedToConnectionStatus = (
  status: MmeConnectedStatus = MmeConnectedStatus.None,
): ConnectionStatus => {
  const statusMap = {
    [MmeConnectedStatus.None]: ConnectionStatus.None,
    [MmeConnectedStatus.Connected]: ConnectionStatus.Connected,
    [MmeConnectedStatus.Disconnected]: ConnectionStatus.Disconnected,
  };

  return statusMap[status];
};

interface RadioResponse {
  manufacturer?: string;
  product_class?: string;
  device_serial: string;
  ip_address: string;
  connected: number;
  configured?: number;
  opstate_enabled?: number;
  rf_tx_on?: number;
  gps_connected?: number;
  mme_connected?: MmeConnectedStatus;
  gps_longitude: string;
  gps_latitude: string;
  fsm_state: string;
  rf_tx_desired: number;
  enb_status?: EnbStatus;
  sas_status?: SasStatus;
  software_version: string;
  hardware_version: string;
  cpi_enabled?: boolean;
  dp_enabled: boolean;
  dp_response_code?: string;
  dp_response_message?: string;
  is_antenna_external?: boolean;
  transmit_enabled?: boolean;
  is_activated: boolean;
  consent_required?: boolean;
  consent_given?: boolean;
}

export const radioApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: Config.AgwApiBaseUrl }),
  reducerPath: "radio",
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    radioStats: builder.query<RadioInfo[], void>({
      query: () => ({
        url: "/enb-stats",
        method: "GET",
      }),
      transformResponse: (data: RadioResponse[] = []): RadioInfo[] => {
        return data.map((radioInfo: RadioResponse): RadioInfo => {
          // GPS
          const gpsStatus: GpsStatus =
            radioInfo.gps_connected === 1 ? GpsStatus.Locked : GpsStatus.Trying;

          const dpEnabled = radioInfo.dp_enabled;

          const dpResponseCodeParsed = parseInt(radioInfo.dp_response_code || "", 10);
          const dpResponseCode = !isNaN(dpResponseCodeParsed) ? dpResponseCodeParsed : undefined;

          // Spectrum
          let spectrumStatus: SpectrumStatus;

          // need radioInfo.sas_status !== SasStatus.SUCCESS check here
          // because dpResponseCode might be absent for this case
          if (
            dpEnabled &&
            radioInfo.sas_status !== SasStatus.Success &&
            radioInfo.sas_status !== SasStatus.Trying
          ) {
            switch (dpResponseCode) {
              case DpResponseCode.Success:
                spectrumStatus = SpectrumStatus.Success;
                break;
              case DpResponseCode.Version:
              case DpResponseCode.Blacklisted:
              case DpResponseCode.MissingParam:
              case DpResponseCode.InvalidValue:
              case DpResponseCode.CertError:
              case DpResponseCode.Deregister:
              case DpResponseCode.RegPending:
              case DpResponseCode.GroupError:
              case DpResponseCode.UnsupportedSpectrum:
              case DpResponseCode.Interference:
              case DpResponseCode.GrantConflict:
              case DpResponseCode.TerminatedGrant:
                spectrumStatus = SpectrumStatus.Failure;
                break;
              case DpResponseCode.SuspendedGrant:
              case DpResponseCode.UnsyncOpParam:
                spectrumStatus = SpectrumStatus.Connected;
                break;
              default:
                spectrumStatus = SpectrumStatus.None;
                break;
            }
          } else {
            switch (radioInfo.sas_status) {
              case SasStatus.Unknown:
              case SasStatus.Idle:
                spectrumStatus = SpectrumStatus.None;
                break;
              case SasStatus.Trying:
                spectrumStatus = SpectrumStatus.Trying;
                break;
              case SasStatus.Connected:
                spectrumStatus = SpectrumStatus.Connected;
                break;
              case SasStatus.Success:
                spectrumStatus = SpectrumStatus.Success;
                break;
              case SasStatus.Disconnected:
              case SasStatus.Failure:
                spectrumStatus = SpectrumStatus.Failure;
                break;
              default:
                spectrumStatus = SpectrumStatus.None;
                break;
            }
          }

          const coreConnectionStatus = mmeConnectedToConnectionStatus(radioInfo.mme_connected);
          const airStatus = radioInfo.rf_tx_on === 1 ? OnOffStatus.On : OnOffStatus.Off;
          const latitude = parseFloat(radioInfo.gps_latitude);
          const longitude = parseFloat(radioInfo.gps_longitude);

          return {
            gpsStatus,
            latitude,
            longitude,
            isGpsReady: gpsStatus === GpsStatus.Locked && !isNaN(latitude) && !isNaN(longitude),
            spectrumStatus,
            coreConnectionStatus,
            airStatus,
            serial: radioInfo.device_serial,
            hardwareVersion: radioInfo.hardware_version,
            softwareVersion: radioInfo.software_version,
            cpiEnabled: Boolean(radioInfo.cpi_enabled),
            dpEnabled,
            dpResponseCode,
            dpResponseMessage: radioInfo.dp_response_message || "",
            isAntennaExternal: radioInfo.is_antenna_external || false,
            manufacturer: radioInfo.manufacturer,
            productClass: radioInfo.product_class,
            model: radioProductClassToModel(radioInfo.product_class),
            transmitEnabled: radioInfo.transmit_enabled || false,
            isActivated: radioInfo.is_activated,
            migration: {
              consentRequired: !!radioInfo.consent_required,
              consentGiven: !!radioInfo.consent_given,
            },
          };
        });
      },
    }),
    giveConsent: builder.mutation<void, { serialNumber: string }>({
      query: ({ serialNumber }) => ({
        url: `/enbs/${serialNumber}/give-consent`,
        method: "POST",
      }),
    }),
  }),
});

export const { useRadioStatsQuery, useGiveConsentMutation } = radioApi;
