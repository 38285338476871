import type { ReactNode } from "react";
import { memo } from "react";

import { InputLabel, Stack } from "@mui/material";

import { Tooltip } from "@novalabsxyz/components/core/tooltip";

import { getLabelFromInputName } from "./helpers";

export interface InputWrapperProps {
  name: string;
  /**
   * You can leave label empty to generate default one based on 'name' property.
   * Provide 'false' to hide label, 'true' to reserve it's height with space as content.
   */
  label?: string | boolean;
  tooltip?: string;
  required?: boolean;
  disabled?: boolean;
}

interface InputWrapperPropsWithChildren extends InputWrapperProps {
  children: ReactNode;
}

// TODO: Add color scheme for disabled elements to the theme.
export const InputWrapper = memo<InputWrapperPropsWithChildren>(
  ({ label, name, required = false, disabled = false, tooltip, children }) => (
    <Stack spacing={1}>
      {label !== false && (
        <Tooltip title={tooltip} iconFontSize="extraSmall" placeAfterTarget>
          <InputLabel
            htmlFor={name}
            sx={{ fontWeight: "subtitle1.fontWeight", opacity: disabled ? 0.6 : 1 }}
          >
            {label === true ? <>&nbsp;</> : getLabelFromInputName({ name, label, required })}
          </InputLabel>
        </Tooltip>
      )}
      {children}
    </Stack>
  ),
);
InputWrapper.displayName = "InputWrapper";
