import { memo } from "react";

import {
  useMinerNameQuery,
  useMinerVersionQuery,
  useMinerConnectionStatusQuery,
  useSoftwareVersionQuery,
  useSpeedTestLatestQuery,
  usePrimaryInterfaceQuery,
} from "@novalabsxyz/agw-client/store/api/gateway-stats";
import { Grid, Box, Typography, Stack } from "@novalabsxyz/components/core";
import { StatusOneLineCard } from "@novalabsxyz/components/ui/cards";
import { TimeInMilliseconds } from "@novalabsxyz/constants/time";
import { DownloadIcon, UploadIcon } from "@novalabsxyz/icons";

export enum MinerPanelSyncTestId {
  GridContainer = "grid-container",
  ValidatorStatusCard = "validator-status-card",
  MinerNameCard = "miner-name-card",
  MinerVersionCard = "miner-version-card",
  SpeedTestCard = "speed-test-card",
  NegotiatedLinkSpeedCard = "negotiated-speed-card",
  AgwVersionCard = "agw-version-card",
}

export const MinerPanelSync = memo(() => {
  const queryOptions = {
    pollingInterval: TimeInMilliseconds.OneMinute,
  };

  const { isLoading: isPrimaryInterfaceLoading, data: primaryInterface } = usePrimaryInterfaceQuery(
    undefined,
    queryOptions,
  );
  const { isLoading: isMinerNameLoading, data: minerName } = useMinerNameQuery(
    undefined,
    queryOptions,
  );
  const { isLoading: isMinerVersionLoading, data: minerVersion } = useMinerVersionQuery(
    undefined,
    queryOptions,
  );
  const { isLoading: isMinerConnectionStatusLoading, data: minerConnectionStatus } =
    useMinerConnectionStatusQuery(undefined, queryOptions);
  const { isLoading: isSoftwareVersionLoading, data: softwareVersion } = useSoftwareVersionQuery(
    undefined,
    queryOptions,
  );
  const { isLoading: isSpeedTestLatestLoading, data: speedTestLatest } = useSpeedTestLatestQuery(
    undefined,
    queryOptions,
  );

  return (
    <Box>
      <Grid
        container
        spacing={{
          xs: 2,
          sm: 3,
        }}
        data-testid={MinerPanelSyncTestId.GridContainer}
      >
        <Grid item xs={12} md={6}>
          <StatusOneLineCard
            title="Miner Connection Status:"
            value={minerConnectionStatus?.status}
            severity={minerConnectionStatus?.severity}
            isLoading={isMinerConnectionStatusLoading}
            data-testid={MinerPanelSyncTestId.ValidatorStatusCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatusOneLineCard
            title="AGW Version:"
            value={softwareVersion?.version}
            isLoading={isSoftwareVersionLoading}
            data-testid={MinerPanelSyncTestId.AgwVersionCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatusOneLineCard
            title="Miner Name:"
            value={minerName?.name}
            isLoading={isMinerNameLoading}
            data-testid={MinerPanelSyncTestId.MinerNameCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatusOneLineCard
            title="Miner Version:"
            value={minerVersion?.fw}
            isLoading={isMinerVersionLoading}
            data-testid={MinerPanelSyncTestId.MinerVersionCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatusOneLineCard
            title="Speed Test:"
            value={
              speedTestLatest ? (
                <Stack
                  sx={{ color: "success.main" }}
                  direction="column"
                  alignItems={{ lg: "flex-end" }}
                  spacing={0.5}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <DownloadIcon />
                    <Typography variant="h5">
                      Download&nbsp;
                      <Typography component="span" variant="h4">
                        {speedTestLatest.downloadMbps}
                      </Typography>
                      &nbsp;Mbps
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <UploadIcon />
                    <Typography variant="h5">
                      Upload&nbsp;
                      <Typography component="span" variant="h4">
                        {speedTestLatest.uploadMbps}
                      </Typography>
                      &nbsp;Mbps
                    </Typography>
                  </Stack>
                </Stack>
              ) : undefined
            }
            isLoading={isSpeedTestLatestLoading}
            data-testid={MinerPanelSyncTestId.SpeedTestCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatusOneLineCard
            title="Negotiated Link Speed:"
            tooltipProps={{
              title: (
                <>
                  <Typography variant="body2" paragraph>
                    This is the negotiated link speed between your Gateway WAN port and the network
                    device it is plugged into. Your Speed Test results cannot surpass the this
                    limit. Possible values are 1000 Mbps, 100 Mbps, 10 Mbps.
                  </Typography>
                  <Typography variant="body2" paragraph>
                    If you see a lower number than you expect try:
                    <br />
                    1) unplug-replug your cable to make sure it&apos;s fully seated,
                    <br />
                    2) replace your ethernet cable,
                    <br />
                    3) try a different port on your network device,
                    <br />
                    4) make sure your network device supports higher link speeds.
                  </Typography>
                </>
              ),
            }}
            value={primaryInterface ? `${primaryInterface.linkSpeedMbps} Mbps` : undefined}
            isLoading={isPrimaryInterfaceLoading}
            data-testid={MinerPanelSyncTestId.NegotiatedLinkSpeedCard}
          />
        </Grid>
      </Grid>
    </Box>
  );
});
MinerPanelSync.displayName = "MinerPanelSync";
