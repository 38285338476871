import { memo, useCallback, useMemo } from "react";
import type { ReactNode } from "react";

import type { TypographyProps, TooltipProps } from "@novalabsxyz/components/core";
import {
  CircularLoader,
  Box,
  Divider,
  Card,
  CardContent,
  Stack,
  Typography,
  Tooltip,
} from "@novalabsxyz/components/core";
import {
  ServiceStatusTextLabel,
  ServiceStatusContainedLabel,
} from "@novalabsxyz/components/ui/labels";
import { Severity } from "@novalabsxyz/constants/severity";
import { AvailabilityStatus } from "@novalabsxyz/constants/status";
import { isDictionary, isNumber, isString } from "@novalabsxyz/utils/lodash-plus";

export interface StatusOneLineCardProps {
  title: string;
  textVariant?: TypographyProps["variant"];
  value?: ReactNode;
  valueVariant?: "text" | "contained";
  severity?: Severity;
  tooltipProps?: TooltipProps;
  tooltipTarget?: "title" | "value";
  isLoading?: boolean;
}

export const StatusOneLineCard = memo<StatusOneLineCardProps>(
  ({
    title,
    textVariant = "h5",
    value,
    valueVariant = "text",
    severity,
    tooltipProps,
    tooltipTarget = "value",
    isLoading = false,
    ...rest
  }) => {
    const renderValue = useCallback(() => {
      if (isLoading) {
        return <Typography variant={textVariant}>Loading...</Typography>;
      }
      if (value === undefined) {
        return (
          <ServiceStatusTextLabel
            text={AvailabilityStatus.NotAvailable}
            severity={Severity.Error}
          />
        );
      }
      if (isDictionary(value)) {
        return value;
      }
      if (severity === undefined || (!isString(value) && !isNumber(value))) {
        return <Typography variant={textVariant}>{value}</Typography>;
      }

      switch (valueVariant) {
        case "contained":
          return <ServiceStatusContainedLabel text={value} severity={severity} />;
        case "text":
        default:
          return <ServiceStatusTextLabel text={value} severity={severity} />;
      }
    }, [isLoading, severity, textVariant, value, valueVariant]);

    const nowrap = useMemo(() => valueVariant === "contained", [valueVariant]);

    return useMemo(
      () => (
        <Card
          sx={{
            height: 1,
            minHeight: 80,
            position: "relative",
          }}
          {...rest}
        >
          <CardContent
            sx={{
              height: 1,
              display: "flex",
              alignItems: "center",
              ...(valueVariant === "contained" && {
                paddingY: 1.25,
                paddingRight: 1.25,

                "&:last-child": {
                  paddingBottom: 1.25,
                },
              }),
            }}
          >
            <CircularLoader type="itemOverlay" open={isLoading} />
            <Stack
              sx={{ width: 1 }}
              direction={{
                xs: nowrap ? "row" : "column",
                lg: "row",
              }}
              justifyContent="space-between"
              alignItems={{
                xs: nowrap ? "center" : "initial",
                lg: "center",
              }}
              spacing={2}
            >
              <Box
                sx={{
                  ...(valueVariant === "contained" && {
                    paddingY: 0.75,
                  }),
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant={textVariant}>{title}</Typography>
                  {!!tooltipProps && tooltipTarget === "title" && <Tooltip {...tooltipProps} />}
                </Stack>
              </Box>
              <Divider sx={{ display: { lg: "none" } }} />
              <Box>
                <Stack direction="row" alignItems="center" sx={{ minHeight: 60 }}>
                  {!isLoading &&
                    value !== undefined &&
                    tooltipProps &&
                    tooltipTarget === "value" && (
                      <Tooltip
                        sx={{
                          order: { xs: 1, lg: 0 },
                          marginLeft: { xs: 1, lg: 0 },
                          marginRight: { xs: 0, lg: 1 },
                        }}
                        {...tooltipProps}
                      />
                    )}
                  {renderValue()}
                </Stack>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      ),
      [
        title,
        textVariant,
        nowrap,
        value,
        valueVariant,
        tooltipProps,
        tooltipTarget,
        renderValue,
        isLoading,
        rest,
      ],
    );
  },
);
StatusOneLineCard.displayName = "StatusOneLineCard";
