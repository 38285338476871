import { memo, useCallback, useMemo, useState } from "react";

import { Box, Button, Stack, Typography } from "@novalabsxyz/components/core";
import { ApprovementStatus } from "@novalabsxyz/constants/status";
import { TimeInMilliseconds } from "@novalabsxyz/constants/time";
import { useGetRadioStatusQuery } from "@novalabsxyz/cpi-api-client/react";
import { useBoolean } from "@novalabsxyz/hooks";

import { ConfirmationDialog } from "./confirmation-dialog";
import type { RadioRegistrationDialogProps } from "./dialog";
import { RadioRegistrationDialog } from "./dialog";
import { RadioRegistrationStatusLabel } from "./status-label";

export interface RadioRegistrationDialogTargetProps
  extends Pick<
    RadioRegistrationDialogProps,
    "gatewaySerialNumber" | "gatewayVendor" | "radioInfo"
  > {}

export const RadioRegistrationDialogTarget = memo<RadioRegistrationDialogTargetProps>((props) => {
  const { radioInfo } = props;
  const {
    value: isRegistrationDialogOpen,
    setTrue: openRegistrationDialog,
    setFalse: closeRegistrationDialog,
  } = useBoolean();
  const {
    value: isConfirmationDialogOpen,
    setTrue: openConfirmationDialog,
    setFalse: closeConfirmationDialog,
  } = useBoolean();
  const [registrationId, setRegistrationId] = useState<number | undefined>();

  const {
    data: radioStatus,
    isLoading: isRadioStatusLoading,
    isError: isRadioStatusError,
    refetch: refetchRadioStatus,
  } = useGetRadioStatusQuery(
    { radioSerialNumber: radioInfo.serial },
    {
      pollingInterval: TimeInMilliseconds.OneMinute,
      skip: !radioInfo.cpiEnabled,
    },
  );

  const title = useMemo(
    () =>
      radioStatus?.latestRegistration?.status === ApprovementStatus.Approved
        ? "Re-register radio"
        : "Register Radio",
    [radioStatus],
  );

  const handleRegistrationSubmitted = useCallback(
    (id: number): void => {
      setRegistrationId(id);
      closeRegistrationDialog();
      openConfirmationDialog();
      void refetchRadioStatus();
    },
    [closeRegistrationDialog, openConfirmationDialog, refetchRadioStatus],
  );

  return useMemo(
    () => (
      <>
        <Stack spacing={2}>
          {radioStatus?.latestRegistration && (
            <Stack>
              <Stack direction="row" spacing={1.5}>
                <Typography>Registration:</Typography>
                <RadioRegistrationStatusLabel status={radioStatus.latestRegistration.status} />
              </Stack>
              {radioStatus.latestRegistration.status === ApprovementStatus.Rejected &&
                !!radioStatus?.latestRegistration.rejectionReason && (
                  <Typography>Reason: {radioStatus.latestRegistration.rejectionReason}</Typography>
                )}
            </Stack>
          )}
          <Box>
            <Button
              sx={{
                width: {
                  xs: 1,
                  md: "initial",
                },
              }}
              disabled={
                isRadioStatusLoading ||
                isRadioStatusError ||
                !radioInfo.isGpsReady ||
                radioInfo.migration.consentRequired
              }
              onClick={openRegistrationDialog}
            >
              {title}
            </Button>
          </Box>
          {!radioInfo.isGpsReady && (
            <Typography
              sx={{
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              GPS must be Locked before proceeding
            </Typography>
          )}
        </Stack>
        <RadioRegistrationDialog
          open={isRegistrationDialogOpen}
          onClose={closeRegistrationDialog}
          title={title}
          onSubmitted={handleRegistrationSubmitted}
          {...props}
        />
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          onClose={closeConfirmationDialog}
          title={title}
          registrationId={registrationId}
        />
      </>
    ),
    [
      isRadioStatusLoading,
      isRadioStatusError,
      title,
      radioInfo,
      radioStatus,
      props,
      registrationId,
      isRegistrationDialogOpen,
      openRegistrationDialog,
      closeRegistrationDialog,
      handleRegistrationSubmitted,
      isConfirmationDialogOpen,
      closeConfirmationDialog,
    ],
  );
});
RadioRegistrationDialogTarget.displayName = "RadioRegistrationDialogTarget";
