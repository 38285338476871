import type { ListPage, ListPageSearchQuery } from "./client";

export interface TypeormListItemsDto<T> {
  items: T[];
}

export interface TypeormListMetaDto {
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface TypeormListPageSearchQueryDto extends Omit<ListPageSearchQuery, "rowsPerPage"> {
  limit?: number;
}

export interface TypeormListPageDto<T> extends TypeormListItemsDto<T> {
  meta: TypeormListMetaDto;
  query: TypeormListPageSearchQueryDto;
}

export const transformTypeormListPageSearchQueryToDto = <T extends ListPageSearchQuery>({
  rowsPerPage,
  ...rest
}: T): TypeormListPageSearchQueryDto => ({
  limit: rowsPerPage,
  ...rest,
});

export const transformTypeormListPageFromDto = <T>({
  items,
  meta,
  query,
}: TypeormListPageDto<T>): ListPage<T> => ({
  data: items,
  options: {
    total: meta.totalItems,
    totalPages: meta.totalPages,
    rowsPerPage: meta.itemsPerPage,
    page: meta.currentPage,
    search: query.search,
    sortField: query.sortField,
    sortOrder: query.sortOrder,
  },
});
