import { memo, useMemo, useCallback } from "react";

import { useDispatch } from "react-redux";

import {
  useRebootDeviceMutation,
  useResetCloudConnectionMutation,
  useRestartMinerMutation,
  useUpgradeAgwMutation,
  UpgradeAgwResponseStatus,
  UpgradeAgwResponseMessageByStatus,
} from "@novalabsxyz/agw-client/store/api/miner";
import { Box, Button, Grid, Tooltip } from "@novalabsxyz/components/core";
import { notificationsActions } from "@novalabsxyz/features/notifications";
import { useResponsive } from "@novalabsxyz/hooks";
import { RenewIcon } from "@novalabsxyz/icons";
import { tenantContent } from "@novalabsxyz/tenants";

export const MinerStateManagement = memo(() => {
  const isLessThanMd = useResponsive({ to: "md" });

  const dispatch = useDispatch();
  const [rebootDevice, { isLoading: waitingRebootMiner }] = useRebootDeviceMutation();
  const [resetCloudConnection, { isLoading: waitingResetCloudConnection }] =
    useResetCloudConnectionMutation();
  const [upgradeAgw, { isLoading: waitingUpgradeAgw }] = useUpgradeAgwMutation();
  const [restartMiner, { isLoading: waitingRestartMiner }] = useRestartMinerMutation();

  const handleUpgradeAgwButtonClick = useCallback(async (): Promise<void> => {
    try {
      const response = await upgradeAgw().unwrap();

      const message = UpgradeAgwResponseMessageByStatus[response.status];

      if (response.status === UpgradeAgwResponseStatus.FailedToCheck) {
        dispatch(notificationsActions.openError(message));
      }
      if (response.status === UpgradeAgwResponseStatus.OnLatestVersion) {
        dispatch(notificationsActions.openSuccess(message));
      }
      if (response.status === UpgradeAgwResponseStatus.StartingUpdate) {
        dispatch(notificationsActions.openSuccess(message));
      }
    } catch (error) {
      dispatch(notificationsActions.openError("Failed to Update Gateway"));
    }
  }, [dispatch, upgradeAgw]);

  const handleResetCloudConnectionButtonClick = useCallback(async (): Promise<void> => {
    try {
      await resetCloudConnection().unwrap();
    } catch (error) {
      dispatch(notificationsActions.openError("Failed to reset cloud connection"));
    }
  }, [dispatch, resetCloudConnection]);

  const handleRebootButtonClick = useCallback(async (): Promise<void> => {
    try {
      await rebootDevice().unwrap();
    } catch (error) {
      dispatch(notificationsActions.openError("Failed to reboot"));
    }
  }, [dispatch, rebootDevice]);

  const handleRestartMinerButtonClick = useCallback(async (): Promise<void> => {
    try {
      await restartMiner().unwrap();
    } catch (error) {
      dispatch(notificationsActions.openError("Failed to restart miner"));
    }
  }, [dispatch, restartMiner]);

  return useMemo(
    () => (
      <Box>
        <Grid
          container
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              xs: "stretch",
            },
          }}
          spacing={{
            xs: 2,
            sm: 3,
          }}
        >
          <Grid item>
            <Tooltip
              placeAfterTarget={isLessThanMd}
              title={`This button allows you trigger a re-bootstrapping to refresh the connection to the Network Orchestrator. ${tenantContent.name} Support may ask you to use this, otherwise not usually needed.`}
            >
              <Button
                disabled={waitingResetCloudConnection}
                onClick={handleResetCloudConnectionButtonClick}
                endIcon={<RenewIcon />}
                fullWidth
              >
                Reset cloud connection
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              placeAfterTarget={isLessThanMd}
              title={`This button will reboot (i.e. full power cycle) the entire ${tenantContent.gateway.name}.`}
            >
              <Button disabled={waitingRebootMiner} onClick={handleRebootButtonClick} fullWidth>
                Reboot
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              placeAfterTarget={isLessThanMd}
              title="This button will update gateway firmware to the latest version."
            >
              <Button disabled={waitingUpgradeAgw} onClick={handleUpgradeAgwButtonClick} fullWidth>
                Update Gateway
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              placeAfterTarget={isLessThanMd}
              title="Restart Miner will restart Helium Miner service without affecting other services or Gateway itself."
            >
              <Button
                disabled={waitingRestartMiner}
                onClick={handleRestartMinerButtonClick}
                color="error"
                fullWidth
              >
                Restart miner
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    ),
    [
      handleRebootButtonClick,
      handleResetCloudConnectionButtonClick,
      handleRestartMinerButtonClick,
      handleUpgradeAgwButtonClick,
      isLessThanMd,
      waitingRebootMiner,
      waitingResetCloudConnection,
      waitingRestartMiner,
      waitingUpgradeAgw,
    ],
  );
});
MinerStateManagement.displayName = "MinerStateManagement";
