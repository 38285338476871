import { memo } from "react";

import type { RadioInfo } from "@novalabsxyz/agw-api-client";
import { Helmet } from "@novalabsxyz/components/seo";
import { tenantContent } from "@novalabsxyz/tenants";

import { PollenRadioMigrationBanner } from "./pollen-radio-migration-banner";
import { RadioMetrics } from "./radio-metrics";

export interface RadioInfoPanelProps {
  radioInfo: RadioInfo;
  refetch: () => void;
}

export const RadioInfoPanel = memo<RadioInfoPanelProps>(({ radioInfo, refetch }) => (
  <>
    <Helmet>
      <title>
        {tenantContent.name} - Radio Info - #{radioInfo.serial}
      </title>
    </Helmet>
    {radioInfo.migration.consentRequired ? (
      <PollenRadioMigrationBanner serialNumber={radioInfo.serial} onConsentGiven={refetch} />
    ) : (
      <RadioMetrics radioInfo={radioInfo} />
    )}
  </>
));
RadioInfoPanel.displayName = "RadioInfoPanel";
