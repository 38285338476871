import { useCallback, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

import { assert } from "@novalabsxyz/utils/assert";

export interface UseCounterOptions {
  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  loop?: boolean;
}

export interface UseCounterReturnType {
  value: number;
  nextValue: number;
  previousValue: number;
  setValue: Dispatch<SetStateAction<number>>;
  reset: () => void;
  inc: () => void;
  dec: () => void;
}

export const useCounter = ({
  defaultValue = 0,
  minValue,
  maxValue,
  loop = false,
}: UseCounterOptions): UseCounterReturnType => {
  assert(
    (minValue !== undefined && maxValue !== undefined) ||
      !(minValue !== undefined || maxValue !== undefined),
    "Min and max values should be specified together or none.",
  );

  const [value, setValue] = useState(defaultValue);

  const reset = useCallback(() => setValue(defaultValue), [defaultValue]);
  const getNextValue = useCallback(
    (currentValue: number): number => {
      if (maxValue !== undefined && minValue !== undefined && currentValue + 1 > maxValue) {
        return loop ? minValue : maxValue;
      } else {
        return currentValue + 1;
      }
    },
    [loop, minValue, maxValue],
  );
  const inc = useCallback(() => setValue(getNextValue(value)), [value, getNextValue]);
  const getPreviousValue = useCallback(
    (currentValue: number): number => {
      if (maxValue !== undefined && minValue !== undefined && currentValue - 1 < minValue) {
        return loop ? maxValue : minValue;
      } else {
        return currentValue - 1;
      }
    },
    [loop, minValue, maxValue],
  );
  const dec = useCallback(() => setValue(getPreviousValue(value)), [value, getPreviousValue]);

  return {
    value,
    nextValue: getNextValue(value),
    previousValue: getPreviousValue(value),
    setValue,
    reset,
    inc,
    dec,
  };
};
