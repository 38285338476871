import { memo } from "react";

import { Stack, Grid, Typography, FormHelperText, FormControl } from "@mui/material";
import type {
  DropzoneInputProps,
  DropzoneRootProps,
  Accept as DropzoneAccept,
} from "react-dropzone";

import { ActionItemStatusCard } from "@novalabsxyz/components/ui/cards/action-item-status";
import { Severity } from "@novalabsxyz/constants/severity";
import { useResponsive } from "@novalabsxyz/hooks";
import { FileFilledGreenIcon } from "@novalabsxyz/icons";

export { DropzoneAccept, DropzoneRootProps, DropzoneInputProps };

export interface DropzoneProps {
  name: string;
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  disabled?: boolean;
  description?: string;
  error?: boolean;
  helperText?: string;
  accept: DropzoneAccept;
}

export const Dropzone = memo<DropzoneProps>(
  ({
    name,
    rootProps,
    inputProps,
    disabled = false,
    description,
    error = false,
    helperText,
    accept,
  }) => {
    // TODO: This technically should be determined based on screen type - touch or not.
    const isLessThanMd = useResponsive({ to: "md" });

    return (
      <FormControl id={name} error={error}>
        <Stack spacing={1}>
          <ActionItemStatusCard
            severity={error ? Severity.Error : Severity.Success}
            disabled={disabled}
            clickable
            sx={{
              paddingX: 4,
              paddingY: 4,
            }}
            {...rootProps}
          >
            <input {...inputProps} />
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              <Grid item>
                <FileFilledGreenIcon
                  sx={{
                    width: "auto",
                    height: 46,
                    opacity: disabled ? 0.5 : 1,
                  }}
                />
              </Grid>
              <Grid item>
                <Stack spacing={0.5}>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    color={disabled ? "grey.400" : "textSecondary"}
                  >
                    {isLessThanMd ? "Click here to browse images" : "Drop your images or browse"}
                  </Typography>
                  <Typography
                    align="center"
                    variant="caption"
                    color={disabled ? "grey.400" : "text.caption"}
                  >
                    {Object.values(accept)
                      .map((resolutionList) =>
                        resolutionList
                          .map((resolution) => resolution.replace(".", "").toUpperCase())
                          .join(", "),
                      )
                      .join(", ")}
                    . Max size 10MB
                    <br />
                    {description}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </ActionItemStatusCard>
          {!!helperText && (
            <FormHelperText sx={{ textAlign: "center" }}>{helperText}</FormHelperText>
          )}
        </Stack>
      </FormControl>
    );
  },
);
Dropzone.displayName = "Dropzone";
