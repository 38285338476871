export interface MinerVersionDTO {
  fw: string;
}

export interface MinerVersion {
  fw: string;
}

export const minerVersionQuery = {
  query: () => ({
    url: "/miner-version",
    method: "GET",
  }),
};
