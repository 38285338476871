import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Config } from "@novalabsxyz/agw-client/config";

export const ueApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: Config.AgwApiBaseUrl }),
  reducerPath: "ue",
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    ueCount: builder.query<number, void>({
      query: () => ({
        url: "/ue-stats",
        method: "GET",
      }),
      transformResponse(data: { count: number }): number {
        return data?.count;
      },
    }),
  }),
});

export const { useUeCountQuery } = ueApi;
