import { useCallback } from "react";

import { HttpError, HttpStatus } from "@novalabsxyz/constants/http-status";
import { notificationsActions } from "@novalabsxyz/features/notifications";
import { routingService } from "@novalabsxyz/routing";
import { useDispatch } from "@novalabsxyz/store";

export interface ApiRequestOptions {
  showNotificationOnUnauthorized?: boolean;
  disableNotifications?: boolean;
}

type ApiRequestFunction = <T extends Array<unknown>, R>(
  func: (...args: T) => Promise<R>,
  funcArgs: T,
  options?: ApiRequestOptions,
) => Promise<R>;

export interface UseApiRequestReturnType {
  apiRequest: ApiRequestFunction;
}

export const useApiRequest = (): UseApiRequestReturnType => {
  const dispatch = useDispatch();

  const apiRequest = useCallback<ApiRequestFunction>(
    async (func, funcArgs, options = {}) => {
      try {
        const response = await func(...funcArgs);

        return response;
      } catch (err) {
        if (err instanceof HttpError) {
          if (!options.showNotificationOnUnauthorized && err.status === HttpStatus.UNAUTHORIZED) {
            if (routingService.signOut) {
              routingService.signOut();
            }
            routingService.redirect("signIn");

            throw err;
          }

          if (!options.disableNotifications) {
            dispatch(notificationsActions.openHttpError(err));
          } else {
            // eslint-disable-next-line no-console
            console.error("Unexpected error:", err);
          }
        }

        throw err;
      }
    },
    [dispatch],
  );

  return { apiRequest };
};
