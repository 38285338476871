import { memo, useMemo } from "react";
import type { ReactNode } from "react";

import ImageLightBox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { IconButton } from "@novalabsxyz/components/core/buttons";
import { CircularLoader } from "@novalabsxyz/components/core/circular-loader";
import { Stack, Typography, Card, Box, alpha } from "@novalabsxyz/components/core/mui";
import { useBoolean, useCounter } from "@novalabsxyz/hooks";
import { FullSizeIcon, FileFilledGreenIcon } from "@novalabsxyz/icons";

declare module "react-image-lightbox" {
  interface ILightBoxProps {
    loader?: ReactNode;
  }
}

export interface Image {
  url: string;
  id: string | number;
  caption?: ReactNode;
  isError?: boolean;
}

export interface ImagesPreviewProps {
  label: string;
  images: Image[];
  displayCaptionInPreviewMode?: boolean;
}

export const ImagesPreview = memo<ImagesPreviewProps>(
  ({ label, images = [], displayCaptionInPreviewMode = false }) => {
    const {
      value: isLightBoxOpened,
      setTrue: openLightBox,
      setFalse: closeLightBox,
    } = useBoolean();
    const {
      value: currentImageIndex,
      nextValue: nextImageIndex,
      previousValue: previousImageIndex,
      inc: goToNextImage,
      dec: goToPreviousImage,
    } = useCounter({ minValue: 0, maxValue: images.length - 1, loop: true });

    return useMemo(() => {
      const currentImage = images[currentImageIndex];

      return (
        <Stack spacing={1}>
          <Typography variant="subtitle1" align="center">
            {label}
          </Typography>
          <Card
            sx={{
              position: "relative",
              height: 140,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: ({ palette }): string =>
                alpha(currentImage?.isError ? palette.error.main : palette.secondary.main, 0.05),
            }}
          >
            {currentImage ? (
              <>
                <Box
                  component="img"
                  src={currentImage.url}
                  alt={`Image ${currentImage.id}`}
                  sx={{ maxWidth: "calc(100% - 32px)", maxHeight: "calc(100% - 32px)" }}
                />
                <IconButton
                  color={currentImage.isError ? "error" : "secondary"}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  onClick={openLightBox}
                >
                  <FullSizeIcon />
                </IconButton>
                {isLightBoxOpened && (
                  // TODO: Issue with loading stuck on open exists only in react strict mode.
                  <ImageLightBox
                    imageCaption={currentImage.caption}
                    mainSrc={currentImage.url}
                    nextSrc={images[nextImageIndex]?.url}
                    prevSrc={images[previousImageIndex]?.url}
                    onMoveNextRequest={goToNextImage}
                    onMovePrevRequest={goToPreviousImage}
                    onCloseRequest={closeLightBox}
                    loader={<CircularLoader type="rawCentered" />}
                  />
                )}
              </>
            ) : (
              <FileFilledGreenIcon />
            )}
          </Card>
          {displayCaptionInPreviewMode && currentImage?.caption}
          <Typography variant="caption" align="center">
            {currentImageIndex + 1} of {images.length}
          </Typography>
        </Stack>
      );
    }, [
      label,
      images,
      displayCaptionInPreviewMode,
      isLightBoxOpened,
      openLightBox,
      closeLightBox,
      currentImageIndex,
      nextImageIndex,
      previousImageIndex,
      goToNextImage,
      goToPreviousImage,
    ]);
  },
);
ImagesPreview.displayName = "ImagesPreview";
