import { memo, useCallback, useMemo } from "react";

import { useField, useFormikContext } from "formik";

import { Select } from "@novalabsxyz/components/core";
import type { SelectProps, SelectChangeEvent } from "@novalabsxyz/components/core";

export const FormSelectField = memo<Omit<SelectProps, "value" | "onChange" | "error">>(
  ({ name, optionForUndefined, helperText, disabled, readOnly, ...props }) => {
    const [{ value }, { touched, error }, { setTouched, setValue }] = useField<unknown | null>(
      name,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { isSubmitting, validateOnMount, status } = useFormikContext();

    const handleBlur = useCallback(() => {
      setTouched(true);
    }, [setTouched]);

    const handleChange = useCallback(
      ({ target }: SelectChangeEvent<unknown>) => {
        setTouched(true);
        setValue(optionForUndefined?.value === target.value ? null : target.value);
      },
      [optionForUndefined?.value, setValue, setTouched],
    );

    return useMemo(
      () => (
        <Select
          {...props}
          name={name}
          value={optionForUndefined && value === null ? optionForUndefined.value : value}
          optionForUndefined={optionForUndefined}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={readOnly || status === "readOnly"}
          disabled={isSubmitting || disabled}
          error={(validateOnMount || touched) && error !== undefined}
          helperText={((validateOnMount || touched) && error) || helperText}
        />
      ),
      [
        name,
        value,
        optionForUndefined,
        error,
        helperText,
        disabled,
        touched,
        isSubmitting,
        readOnly,
        validateOnMount,
        status,
        props,
        handleBlur,
        handleChange,
      ],
    );
  },
);
FormSelectField.displayName = "FormSelectField";
