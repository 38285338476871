import { memo, useMemo } from "react";

import type { TypographyProps } from "@novalabsxyz/components/core";
import { Stack, Typography, Tooltip } from "@novalabsxyz/components/core";
import { ApprovementStatus } from "@novalabsxyz/constants/status";
import {
  ApproveIcon,
  RejectIcon,
  WarningIcon,
  PendingIcon,
  DeleteFileIcon,
} from "@novalabsxyz/icons";
import type { SvgIconProps } from "@novalabsxyz/icons";

export interface RadioRegistrationStatusLabelProps {
  status?: ApprovementStatus;
  typographyProps?: TypographyProps;
  hideTooltip?: boolean;
}

export const RadioRegistrationStatusLabel = memo<RadioRegistrationStatusLabelProps>(
  ({ status, typographyProps, hideTooltip }) => {
    const { Icon, color, tooltip } = useMemo(() => {
      switch (status) {
        case ApprovementStatus.Approved:
          return {
            Icon: ApproveIcon,
            color: "success",
          };
        case ApprovementStatus.Rejected:
          return {
            Icon: RejectIcon,
            color: "error",
            tooltip: 'Please re-submit CPI registration using the "Re-register Radio" button.',
          };
        case ApprovementStatus.Verification:
          return {
            Icon: PendingIcon,
            color: "info",
            tooltip: "Your devices are being verified, please keep them powered.",
          };
        case ApprovementStatus.Pending:
        case ApprovementStatus.InReview:
          return {
            Icon: PendingIcon,
            color: "info",
          };
        case ApprovementStatus.Archived:
          return {
            Icon: DeleteFileIcon,
            color: "info",
          };
        case undefined:
        default:
          return {
            Icon: WarningIcon,
            color: "warning",
            tooltip: 'Please register your Radio with CPI using the "Register Radio" button.',
          };
      }
    }, [status]);

    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Stack justifyContent="center">
          <Icon fontSize="small" color={color as SvgIconProps["color"]} />
        </Stack>
        <Tooltip title={hideTooltip ? undefined : tooltip}>
          <Typography component="span" color={`${color}.main`} {...typographyProps}>
            {status || "Not Requested"}
          </Typography>
        </Tooltip>
      </Stack>
    );
  },
);
RadioRegistrationStatusLabel.displayName = "RadioRegistrationStatusLabel";
