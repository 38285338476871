import type { SvgIconType } from "@novalabsxyz/icons";
import type { OverridableStringUnion } from "@novalabsxyz/types";
import { assert } from "@novalabsxyz/utils/assert";
import { getEnvValue } from "@novalabsxyz/utils/env";
import { yup } from "@novalabsxyz/utils/yup";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RouteOverrides {}

export type Route = OverridableStringUnion<
  "home" | "signIn" | "signOut" | "signedIn",
  RouteOverrides
>;

export interface RouteConfig {
  pathname: string;
  label: string;
  shortLabel?: string;
  isMenuItem?: boolean;
  Icon?: SvgIconType;
  disabled?: boolean;
}
export type RoutesConfig = Record<Route, RouteConfig>;

export interface RouteInfo extends RouteConfig {
  route: Route;
}
export type RoutesInfo = {
  [key in Route]?: RouteInfo;
};

export interface RoutingServiceConfig {
  routes: RoutesConfig;
  redirect: (path: string) => void;
  signOut?: () => void;
}

export class RoutingService {
  protected name = "Routing Service";

  private _routesInfo: RoutesInfo | undefined;
  private _origin: string | undefined;

  public redirect: (route: Route) => void;
  public signOut?: () => void;

  constructor() {
    this._origin = getEnvValue("PUBLIC_ORIGIN", yup.string());
  }

  private _prepareRoute(route: Route, routeConfig: RouteConfig): RouteInfo {
    return {
      ...routeConfig,
      route,
    };
  }

  private _prepareRoutes(routes: RoutesConfig): RoutesInfo {
    return Object.fromEntries(
      Object.entries<RouteConfig>(routes).map(([route, routeConfig]) => [
        route,
        this._prepareRoute(route as Route, routeConfig),
      ]),
    );
  }

  public init({ routes, redirect, signOut }: RoutingServiceConfig): void {
    this._routesInfo = this._prepareRoutes(routes);
    this.redirect = (route: Route): void => {
      redirect(this.getRoutePathname(route));
    };
    this.signOut = signOut;
  }

  protected assertInitialized(
    condition: unknown,
    message = "Service is not initialized. Please provide a valid config first.",
  ): asserts condition {
    assert(condition, `${this.name} ${message}`);
  }

  public get routesInfo(): RoutesInfo {
    this.assertInitialized(this._routesInfo);

    return this._routesInfo;
  }

  public get origin(): string {
    this.assertInitialized(this._origin, "Origin is not provided.");

    return this._origin;
  }

  public getRoute(route: Route): RouteInfo {
    const routeInfo = this.routesInfo[route];

    assert(routeInfo, `Route '${route}' not found.`);
    assert(!routeInfo.disabled, `Route ${route} is disabled.`);

    return routeInfo;
  }

  public getRoutePathname(route: Route): string {
    return this.getRoute(route).pathname;
  }

  public getRoutePathnameWithOrigin(route: Route): string {
    return `${this.origin}${this.getRoutePathname(route)}`;
  }

  public get menuRoutes(): RouteInfo[] {
    return Object.values(this.routesInfo).filter((value) => value.isMenuItem);
  }
}
