import { Severity } from "@novalabsxyz/constants/severity";

export enum ApprovementStatus {
  Verification = "Verification",
  Pending = "Pending",
  InReview = "In Review",
  Rejected = "Rejected",
  Approved = "Approved",
  Archived = "Archived",
}

export const readOnlyApprovementStatuses = [
  ApprovementStatus.Verification,
  ApprovementStatus.Archived,
];
export const writeableApprovementStatuses = Object.values(ApprovementStatus).filter(
  (status) => !readOnlyApprovementStatuses.includes(status),
);

export const approvementToSeverity = (
  status: ApprovementStatus = ApprovementStatus.Approved,
): Severity => {
  const statusMap = {
    [ApprovementStatus.Approved]: Severity.Success,
    [ApprovementStatus.Rejected]: Severity.Error,
    [ApprovementStatus.Verification]: Severity.Info,
    [ApprovementStatus.Pending]: Severity.Info,
    [ApprovementStatus.InReview]: Severity.Info,
    [ApprovementStatus.Archived]: Severity.Info,
  };

  return statusMap[status];
};
