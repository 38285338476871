import { configureStore } from "@reduxjs/toolkit";

import { cpiApiRtk } from "@novalabsxyz/cpi-api-client/react";
import { notificationsSlice } from "@novalabsxyz/features/notifications/store";
import { systemMiddleware } from "@novalabsxyz/store/middleware";

import { gatewayStatsApi } from "./api/gateway-stats";
import { minerApi } from "./api/miner";
import { radioApi } from "./api/radio";
import { ueApi } from "./api/ue";

export const store = configureStore({
  reducer: {
    [minerApi.reducerPath]: minerApi.reducer,
    [radioApi.reducerPath]: radioApi.reducer,
    [cpiApiRtk.reducerPath]: cpiApiRtk.reducer,
    [ueApi.reducerPath]: ueApi.reducer,
    [gatewayStatsApi.reducerPath]: gatewayStatsApi.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      minerApi.middleware,
      radioApi.middleware,
      cpiApiRtk.middleware,
      ueApi.middleware,
      gatewayStatsApi.middleware,
      ...systemMiddleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
