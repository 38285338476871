import { memo, useCallback, useEffect, useMemo, useState } from "react";
import type {
  ClipboardEventHandler,
  KeyboardEventHandler,
  WheelEventHandler,
  FocusEventHandler,
} from "react";

import { isNaN, round } from "@novalabsxyz/utils/lodash-plus";

import { TextField } from "./text-field";
import type { TextFieldProps } from "./text-field";

export interface NumberFieldProps
  extends Omit<
    TextFieldProps,
    "value" | "defaultValue" | "onChange" | "type" | "multiline" | "minRows" | "maxRows" | "rows"
  > {
  value: number | null;
  defaultValue?: number | null;
  integer?: boolean;
  positive?: boolean;
  maxPrecision?: number;
  onChange?: (value: number | null) => void;
}
export const NumberField = memo<NumberFieldProps>(
  ({
    value: propsValue = null,
    defaultValue = null,
    onBlur,
    onChange,
    positive = false,
    integer = false,
    maxPrecision = integer ? 0 : undefined,
    inputProps,
    ...rest
  }) => {
    const valueUtils = useMemo(() => {
      const regexp = new RegExp(
        `^${!positive ? "[-+]?" : ""}\\d*${!integer ? "[\\.,]?\\d*" : ""}$`,
      );

      const roundValue = (value: number | null): number | null =>
        value === null ? null : round(value, maxPrecision || 21);
      const parseValue = (value: string | number | null): number | null => {
        if (value === null) {
          return null;
        }

        const parsed = parseFloat(value.toString());
        return isNaN(parsed) ? null : parsed;
      };

      return {
        regexp,
        isCorrect: (value: string): boolean => regexp.test(value),
        round: roundValue,
        parse: parseValue,
        toFixed: (value: string | number | null): string => {
          const rounded = roundValue(parseValue(value));
          if (rounded === null) {
            return "";
          }

          return maxPrecision ? rounded.toFixed(maxPrecision) : rounded.toString();
        },
      };
    }, [positive, integer, maxPrecision]);

    const [inputValue, setInputValue] = useState(valueUtils.toFixed(defaultValue));

    useEffect(() => {
      setInputValue((currentValue) => {
        if (propsValue === null) {
          return "";
        }

        return parseFloat(currentValue) === propsValue
          ? currentValue
          : valueUtils.toFixed(propsValue);
      });
    }, [propsValue, valueUtils]);

    const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
      (event) => {
        setInputValue(valueUtils.toFixed(inputValue));
        if (onChange) {
          onChange(valueUtils.round(valueUtils.parse(inputValue)));
        }
        if (onBlur) {
          onBlur(event);
        }
      },
      [inputValue, onChange, onBlur, valueUtils],
    );

    const handleChange = useCallback(
      (value: string) => {
        setInputValue(value);
        if (onChange) {
          const parsedValue = integer ? parseInt(value, 10) : parseFloat(value);
          onChange(isNaN(parsedValue) ? null : parsedValue);
        }
      },
      [integer, onChange],
    );

    const handleWheel = useCallback<WheelEventHandler<HTMLInputElement>>((event) => {
      event.currentTarget.blur();
    }, []);

    const handlePaste = useCallback<ClipboardEventHandler<HTMLInputElement>>(
      (event) => {
        const value = event.clipboardData.getData("text");
        if (!valueUtils.isCorrect(value)) {
          event.preventDefault();
        }
      },
      [valueUtils],
    );

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
      (event) => {
        // Forbid exponent format.
        if (
          "eE".includes(event.key) ||
          (integer && ".,".includes(event.key)) ||
          (positive && "+-".includes(event.key))
        ) {
          event.preventDefault();
        }
      },
      [positive, integer],
    );

    return useMemo(
      () => (
        <TextField
          type="number"
          value={inputValue}
          onBlur={handleBlur}
          onValueChange={handleChange}
          inputProps={{
            onWheel: handleWheel,
            onPaste: handlePaste,
            onKeyDown: handleKeyDown,
            ...inputProps,
          }}
          {...rest}
        />
      ),
      [
        inputValue,
        handleBlur,
        handleChange,
        inputProps,
        rest,
        handleWheel,
        handlePaste,
        handleKeyDown,
      ],
    );
  },
);
NumberField.displayName = "NumberField";
