export enum MeasurementUnit {
  Meter = "m",
  Foot = "ft",
}

export const measurementUnitInMeters: { [key in MeasurementUnit]: number } = {
  [MeasurementUnit.Meter]: 1,
  [MeasurementUnit.Foot]: 0.3048,
};

export const convertMeasurementUnitsValue = (
  value: number,
  from: MeasurementUnit,
  to: MeasurementUnit,
): number => (measurementUnitInMeters[from] / measurementUnitInMeters[to]) * value;
