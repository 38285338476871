import { memo, useCallback, useMemo } from "react";
import type { ChangeEventHandler, ReactElement } from "react";

import type { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { TextField as MuiTextField, InputAdornment } from "@mui/material";

import type { InputWrapperProps } from "@novalabsxyz/components/core/inputs/wrapper";
import { InputWrapper } from "@novalabsxyz/components/core/inputs/wrapper";

import { getPlaceholderFromInputName } from "./helpers";

export interface TextFieldProps
  extends InputWrapperProps,
    Pick<
      MuiTextFieldProps,
      | "name"
      | "value"
      | "defaultValue"
      | "type"
      | "placeholder"
      | "disabled"
      | "onChange"
      | "onBlur"
      | "onKeyPress"
      | "error"
      | "helperText"
      | "inputProps"
      | "inputRef"
      | "multiline"
      | "minRows"
      | "maxRows"
      | "rows"
    > {
  name: string;
  /**
   * You can leave placeholder empty to generate default one based on 'name' property.
   */
  placeholder?: string;
  onValueChange?: (value: string) => void;
  startAdornment?: ReactElement;
  endAdornment?: ReactElement;
  readOnly?: boolean;
}

export const TextField = memo<TextFieldProps>(
  ({
    label,
    name,
    placeholder,
    required,
    disabled,
    tooltip,
    readOnly = false,
    startAdornment,
    endAdornment,
    onChange,
    onValueChange,
    ...rest
  }) => {
    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        if (onChange) {
          onChange(event);
        }
        if (onValueChange) {
          onValueChange(event.target.value);
        }
      },
      [onChange, onValueChange],
    );

    return useMemo(
      () => (
        <InputWrapper
          name={name}
          label={label}
          tooltip={tooltip}
          required={required}
          disabled={disabled}
        >
          <MuiTextField
            id={name}
            name={name}
            placeholder={getPlaceholderFromInputName({ name, placeholder, label })}
            onChange={handleChange}
            disabled={disabled}
            InputProps={{
              startAdornment: startAdornment ? (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
              ) : undefined,
              endAdornment: endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ) : undefined,
              readOnly,
            }}
            {...rest}
          />
        </InputWrapper>
      ),
      [
        name,
        label,
        placeholder,
        required,
        disabled,
        tooltip,
        readOnly,
        startAdornment,
        endAdornment,
        handleChange,
        rest,
      ],
    );
  },
);
TextField.displayName = "TextField";
