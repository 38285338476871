export function assert(condition: unknown, message?: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}

export function assertRelationsLoaded(
  condition: unknown,
  message = "Relations are not loaded correctly.",
): asserts condition {
  return assert(condition, message);
}
