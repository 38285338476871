import { memo } from "react";

import {
  MinerDashboardPage,
  MinerDashboardHeaderEnd,
} from "@novalabsxyz/agw-client/pages/miner-dashboard";
import { RadioDashboardPage } from "@novalabsxyz/agw-client/pages/radio-dashboard";
import { Box, Tabs, Typography, Link } from "@novalabsxyz/components/core";

enum Tab {
  MinerDashboard = "Miner dashboard",
  RadioDashboard = "Radio dashboard",
}

// TODO: Update service to router usage.
export const Content = memo(() => (
  <Box sx={{ height: 1 }}>
    <Typography sx={{ position: "relative", zIndex: 1 }} variant="subtitle1">
      Local Dashboard is being deprecated. Please use{" "}
      <Link color="secondary" href="https://hotspots.hellohelium.com/" target="_blank">
        Hotspot Cloud Dashboard
      </Link>
      .
    </Typography>
    <Tabs
      tabs={{
        [Tab.MinerDashboard]: <MinerDashboardPage />,
        [Tab.RadioDashboard]: <RadioDashboardPage />,
      }}
      defaultTab={Tab.MinerDashboard}
      headerEnd={{
        [Tab.MinerDashboard]: <MinerDashboardHeaderEnd />,
      }}
      fullHeight
    />
  </Box>
));
Content.displayName = "Content";
