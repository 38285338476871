import { memo, useCallback, useEffect, useMemo } from "react";

import { useGiveConsentMutation } from "@novalabsxyz/agw-client/store/api/radio";
import { Box, Stack, Typography, Button, CircularLoader } from "@novalabsxyz/components/core";

export interface PollenRadioMigrationBannerProps {
  serialNumber: string;
  onConsentGiven: () => void;
}

export const PollenRadioMigrationBanner = memo<PollenRadioMigrationBannerProps>(
  ({ serialNumber, onConsentGiven }) => {
    const [giveConsent, { isLoading, isSuccess }] = useGiveConsentMutation();

    const handleAgreementButtonClick = useCallback(() => {
      void giveConsent({ serialNumber });
    }, [serialNumber, giveConsent]);

    useEffect(() => {
      if (isSuccess) {
        onConsentGiven();
      }
    }, [isSuccess, onConsentGiven]);

    return useMemo(
      () => (
        <>
          <CircularLoader open={isLoading} type="itemOverlay" />
          <Box
            sx={{
              paddingTop: 5,
              height: 1,
              maxWidth: ({ breakpoints }) => breakpoints.values.sm,
              marginX: "auto",
            }}
          >
            <Stack sx={{ height: 1 }} justifyContent="center" alignItems="center" spacing={5}>
              <Typography variant="h4" component="p" fontWeight={500} align="center">
                By pressing a button below, you acknowledge that converting your Pollen radio to a
                Nova Labs Radio (compatible with the Helium Network) is a one-way migration. Moving
                back after migration will require intervention from Pollen support.
              </Typography>
              <Typography variant="h4" component="p" fontWeight={500} align="center">
                Also, please file a Pollen support request to deregister your radio from the SAS.{" "}
                <b>
                  This migration will work on this gateway only until Pollen deregisters the Radio.
                </b>{" "}
                If you plan to move the radio to a different FreedomFi Gateway, move it before doing
                the migration.
              </Typography>
              <Button disabled={isLoading} onClick={handleAgreementButtonClick}>
                Confirm
              </Button>
            </Stack>
          </Box>
        </>
      ),
      [isLoading, handleAgreementButtonClick],
    );
  },
);
PollenRadioMigrationBanner.displayName = "PollenRadioMigrationBanner";
