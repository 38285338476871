import { memo } from "react";

import {
  Dialog,
  DialogHeader,
  DialogContent,
  CircularLoader,
  Typography,
} from "@novalabsxyz/components/core";
import type { RadioRegistrationPrerequisitesSearchQuery } from "@novalabsxyz/cpi-api-client";
import { useGetRadioRegistrationPrerequisitesQuery } from "@novalabsxyz/cpi-api-client/react";
import { useResponsive } from "@novalabsxyz/hooks";
import { pick } from "@novalabsxyz/utils/lodash-plus";

import type { RadioRegistrationFormProps } from "./form";
import { RadioRegistrationForm } from "./form";

export interface RadioRegistrationDialogProps
  extends Omit<RadioRegistrationFormProps, "prerequisites"> {
  open: boolean;
  onClose: () => void;
  title: string;
}

export const RadioRegistrationDialog = memo<RadioRegistrationDialogProps>(
  ({ open, onClose, title, ...props }) => {
    const { radioInfo } = props;
    const isLessThanLg = useResponsive({ to: "lg" });

    const {
      data: prerequisites,
      isLoading: isPrerequisitesLoading,
      isError: isPrerequisitesError,
    } = useGetRadioRegistrationPrerequisitesQuery(
      pick(radioInfo, [
        "manufacturer",
        "productClass",
      ]) as RadioRegistrationPrerequisitesSearchQuery,
    );

    return (
      <Dialog open={open} onClose={onClose} fullScreen={isLessThanLg} maxWidth="md">
        <DialogHeader onClose={onClose}>{title}</DialogHeader>
        <DialogContent sx={{ position: "relative" }}>
          {isPrerequisitesLoading ? (
            <>
              <CircularLoader type="itemOverlay" />
              <Typography align="center" sx={{ marginY: 5 }}>
                Loading...
              </Typography>
            </>
          ) : null}
          {isPrerequisitesError ? (
            <Typography color="error">Failed to load radio registration prerequisites</Typography>
          ) : null}
          {prerequisites ? (
            <RadioRegistrationForm prerequisites={prerequisites} {...props} />
          ) : null}
        </DialogContent>
      </Dialog>
    );
  },
);
RadioRegistrationDialog.displayName = "RadioRegistrationDialog";
