import type { Severity } from "@novalabsxyz/constants/severity";
import type { UpDownStatus } from "@novalabsxyz/constants/status";
import { boolToUpDownStatus, upDownToSeverity } from "@novalabsxyz/constants/status";

export interface PrimaryInterfaceDTO {
  config: string;
  isUp: boolean;
  linkSpeedMbps: number;
}

export interface PrimaryInterface {
  status: UpDownStatus;
  config: string;
  severity: Severity;
  linkSpeedMbps: number;
}

const transformResponse = ({ config, isUp, ...rest }: PrimaryInterfaceDTO): PrimaryInterface => {
  const status = boolToUpDownStatus(isUp);
  return {
    status,
    config,
    severity: upDownToSeverity(status),
    ...rest,
  };
};

export const primaryInterfaceQuery = {
  query: () => ({
    url: "/primary-interface",
    method: "GET",
  }),
  transformResponse,
};
