import { memo } from "react";

import { Box, Backdrop, CircularProgress } from "@mui/material";

export interface CircularLoaderProps {
  open?: boolean;
  /**
   * Indicates how loader will arrive on the page.
   *
   * 'raw' - Loader appears as a child of a parent (raw).
   * 'rawCantered' - Loader appears as a child in a center of a positioned parent (raw).
   * 'itemOverlay' - Loader appears as with backdrop that covers whole positioned parent element.
   * 'pageOverlay' - Loader appears on a overlay layer above all the page elements.
   */
  type?: "raw" | "rawCentered" | "itemOverlay" | "pageOverlay";
  transparentBackground?: boolean;
}

export const CircularLoader = memo<CircularLoaderProps>(
  ({ type = "raw", open = true, transparentBackground = false }) => {
    switch (type) {
      case "pageOverlay":
        return (
          <Backdrop
            sx={{
              zIndex: "snackbar",
              ...(transparentBackground && {
                backgroundColor: "transparent",
              }),
            }}
            open={open}
          >
            <CircularProgress />
          </Backdrop>
        );
      case "itemOverlay":
        return (
          <Box
            sx={{
              position: "absolute",
              zIndex: 10,
              background: transparentBackground ? "transparent" : "rgba(0, 0, 0, 0.15)",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: open ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        );
      case "rawCentered":
        return (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        );
      case "raw":
      default:
        return (
          <CircularProgress
            sx={{
              ...(!open && {
                display: "none",
              }),
            }}
          />
        );
    }
  },
);
CircularLoader.displayName = "CircularLoader";
