import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Config } from "@novalabsxyz/agw-client/config";

import type { HeliumDashboardAccess } from "./helium-dashboard-access";
import { heliumDashboardAccessQuery } from "./helium-dashboard-access";
import type { InternetAccess } from "./internet-access";
import { internetAccessQuery } from "./internet-access";
import type { MagmaAccess } from "./magma-access";
import { magmaAccessQuery } from "./magma-access";
import type { MinerConnectionStatus } from "./miner-connection-status";
import { minerConnectionStatusQuery } from "./miner-connection-status";
import type { MinerName } from "./miner-name";
import { minerNameQuery } from "./miner-name";
import type { MinerVersion } from "./miner-version";
import { minerVersionQuery } from "./miner-version";
import type { PrimaryInterface } from "./primary-interface";
import { primaryInterfaceQuery } from "./primary-interface";
import type { SerialId } from "./serial-id";
import { serialIdQuery } from "./serial-id";
import type { SoftwareVersion } from "./software-version";
import { softwareVersionQuery } from "./software-version";
import type { SpeedTestLatest } from "./speed-test";
import { speedTestLatestQuery } from "./speed-test";
import type { ZtpAccess } from "./ztp-access";
import { ztpAccessQuery } from "./ztp-access";

export const gatewayStatsApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.AgwApiBaseUrl}/stats` }),
  reducerPath: "gatewayStatsApi",
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    primaryInterface: builder.query<PrimaryInterface, void>(primaryInterfaceQuery),
    internetAccess: builder.query<InternetAccess, void>(internetAccessQuery),
    magmaAccess: builder.query<MagmaAccess, void>(magmaAccessQuery),
    heliumDashboardAccess: builder.query<HeliumDashboardAccess, void>(heliumDashboardAccessQuery),
    ztpAccess: builder.query<ZtpAccess, void>(ztpAccessQuery),
    serialId: builder.query<SerialId, void>(serialIdQuery),
    minerName: builder.query<MinerName, void>(minerNameQuery),
    minerVersion: builder.query<MinerVersion, void>(minerVersionQuery),
    minerConnectionStatus: builder.query<MinerConnectionStatus, void>(minerConnectionStatusQuery),
    softwareVersion: builder.query<SoftwareVersion, void>(softwareVersionQuery),
    speedTestLatest: builder.query<SpeedTestLatest, void>(speedTestLatestQuery),
  }),
});

export const {
  usePrimaryInterfaceQuery,
  useInternetAccessQuery,
  useMagmaAccessQuery,
  useHeliumDashboardAccessQuery,
  useZtpAccessQuery,
  useSerialIdQuery,
  useMinerNameQuery,
  useMinerVersionQuery,
  useMinerConnectionStatusQuery,
  useSoftwareVersionQuery,
  useSpeedTestLatestQuery,
} = gatewayStatsApi;

export type {
  PrimaryInterface,
  InternetAccess,
  MagmaAccess,
  HeliumDashboardAccess,
  ZtpAccess,
  SerialId,
  MinerName,
  MinerVersion,
  MinerConnectionStatus,
  SoftwareVersion,
  SpeedTestLatest,
};
