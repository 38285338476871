export interface MinerNameDTO {
  name: string;
}

export interface MinerName {
  name: string;
}

export const minerNameQuery = {
  query: () => ({
    url: "/miner-name",
    method: "GET",
  }),
};
