import { memo } from "react";
import type { ReactNode } from "react";

import { Container, AppBar, Toolbar, Box, Stack, IconButton } from "@novalabsxyz/components/core";
import { CloseMenuIcon, OpenMenuIcon } from "@novalabsxyz/icons";
import { tenantContent } from "@novalabsxyz/tenants";

export interface HeaderProps {
  fixedContainer?: boolean;
  children?: ReactNode;
  isSidebarOpened?: boolean;
  onToggleIsSidebarOpened?: () => void;
}

export const Header = memo<HeaderProps>(
  ({ fixedContainer, children, isSidebarOpened = false, onToggleIsSidebarOpened }) => (
    <AppBar sx={{ backgroundColor: "background.contrast" }} component="header" position="static">
      <Container fixed={fixedContainer}>
        <Toolbar disableGutters>
          <Stack
            sx={{ width: 1 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <Box
              component="img"
              sx={{
                height: {
                  xs: 24,
                  md: 30,
                  lg: 34,
                },
              }}
              src={tenantContent.logo.url}
              alt={tenantContent.logo.alt}
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Box>{children}</Box>
              {onToggleIsSidebarOpened && (
                <IconButton onClick={onToggleIsSidebarOpened}>
                  {isSidebarOpened ? (
                    <CloseMenuIcon sx={{ width: 32, height: 32 }} color="primary" />
                  ) : (
                    <OpenMenuIcon sx={{ width: 32, height: 32 }} color="primary" />
                  )}
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  ),
);
Header.displayName = "Header";
