import createHttpError, { isHttpError, HttpError } from "http-errors";
// eslint-disable-next-line import/no-named-as-default
import HttpStatus from "http-status";

// TODO: Think about possible custom implementation, but this is fine for now.
// Minimal polyfill to handle `captureStackTrace` in non-v8 browsers,
// as `http-errors` using it and rejects to add validation for browsers
// https://github.com/jshttp/http-errors/issues/28.
Error.captureStackTrace = (): void => undefined;

export { HttpStatus, createHttpError, isHttpError, HttpError };

export const uiMessageForHttpStatus: { [key: number]: string } = {
  [HttpStatus.BAD_REQUEST]:
    "Seems that parameters you used for the request are incorrect. Please update them and try again.",
  [HttpStatus.UNAUTHORIZED]: "You need to authorize to perform this action.",
  [HttpStatus.FORBIDDEN]: "You have no rights to perform this action.",
  [HttpStatus.NOT_FOUND]:
    "Seems like item you are looking for does not exist. Please try to update request parameters and try again.",
  [HttpStatus.INTERNAL_SERVER_ERROR]:
    "Something went wrong... Please check your internet connection and try again.",
};

export interface HttpErrorUIMessage {
  title: string;
  message: string;
}
export const getUIMessageForHttpError = (error: HttpError): HttpErrorUIMessage => {
  const title = HttpStatus[error.statusCode] || "";
  let message = error.message;
  if ((!message || message === title) && uiMessageForHttpStatus[error.statusCode]) {
    message = uiMessageForHttpStatus[error.statusCode] || "";
  }

  return { title, message };
};
