import { memo } from "react";

import {
  Dialog,
  DialogHeader,
  DialogContent,
  Stack,
  Typography,
  Button,
  DialogActions,
} from "@novalabsxyz/components/core";

export interface FinishStepProps {
  open?: boolean;
  onClose: () => void;
  title: string;
  registrationId?: number;
}

export const ConfirmationDialog = memo<FinishStepProps>(
  ({ title, registrationId, open = false, onClose }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader onClose={onClose}>{title}</DialogHeader>
      <DialogContent>
        <Stack alignItems="center" spacing={3}>
          <Typography align="center" variant="h3">
            Confirmation number for CPI Registration ID&nbsp;
            <Typography variant="h3" component="span" color="textSecondary">
              #{registrationId}
            </Typography>
          </Typography>
          <Typography align="center" variant="body2">
            NOTE: &quot;Registration ID&quot; here is just a newly generated unique number to
            reference a particular customer/CPI request. No relation to web order registration ID.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Finish</Button>
      </DialogActions>
    </Dialog>
  ),
);
ConfirmationDialog.displayName = "ConfirmationDialog";
