import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { getUIMessageForHttpError } from "@novalabsxyz/constants/http-status";
import type { HttpError } from "@novalabsxyz/constants/http-status";
import { Severity } from "@novalabsxyz/constants/severity";
import { generateUuid } from "@novalabsxyz/utils/uuid";

// TODO: Move to config on necessity or make global.
const MAX_NOTIFICATIONS = 1;
const NAME = "notifications";

interface NotificationOptions {
  id: string;
}

interface Notification extends NotificationOptions {
  message: string | string[];
  title?: string;
  severity: Severity;
}

type NotificationsState = Notification[];
const initialState: NotificationsState = [];

interface NotificationsStore {
  [NAME]: NotificationsState;
}

const openNotificationPreparer = (
  severity = Severity.Success,
  message: string | string[] = "",
  title?: string,
  options: Partial<NotificationOptions> = {},
): Omit<PayloadAction<Notification>, "type"> => ({
  payload: {
    severity,
    message,
    title,
    id: generateUuid(),
    ...options,
  },
});
const openNotificationReducer = (
  state: NotificationsState,
  { payload }: PayloadAction<Notification>,
): NotificationsState => [...state, payload].slice(-MAX_NOTIFICATIONS);

export const notificationsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    open: {
      reducer: openNotificationReducer,
      prepare: openNotificationPreparer,
    },
    openSuccess: {
      reducer: openNotificationReducer,
      prepare: openNotificationPreparer.bind(undefined, Severity.Success),
    },
    openError: {
      reducer: openNotificationReducer,
      prepare: openNotificationPreparer.bind(undefined, Severity.Error),
    },
    openHttpError: {
      reducer: openNotificationReducer,
      prepare: (error: HttpError, options: Partial<NotificationOptions> = {}) => {
        return {
          payload: {
            severity: Severity.Error,
            ...getUIMessageForHttpError(error),
            id: generateUuid(),
            ...options,
          },
        };
      },
    },
    openInfo: {
      reducer: openNotificationReducer,
      prepare: openNotificationPreparer.bind(undefined, Severity.Info),
    },
    openWarning: {
      reducer: openNotificationReducer,
      prepare: openNotificationPreparer.bind(undefined, Severity.Warning),
    },
    closeById: (state, action: PayloadAction<string>) =>
      state.filter(({ id }) => id !== action.payload),
    closeAll: () => [],
  },
});

export const notificationsActions = notificationsSlice.actions;

const selectAll = (store: NotificationsStore): Notification[] => store[notificationsSlice.name];
export const notificationsSelectors = {
  selectAll,
};
