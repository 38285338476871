import { memo, useMemo } from "react";

import { Stack, Box, Grid, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";

import { SizeInBytes } from "@novalabsxyz/constants/size";
import { startCase } from "@novalabsxyz/utils/lodash-plus";

import { Dropzone } from "./dropzone";
import type { DropzoneAccept } from "./dropzone";
import { FilePreview } from "./file-preview";

export interface FilesDropzoneProps {
  name: string;
  label?: string;
  description?: string;
  multiple?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  accept?: DropzoneAccept;
  files: File[];
  onDrop: (files: File[]) => void;
  onFileDelete: (file: File, index: number) => void;
}

export const FilesDropzone = memo<FilesDropzoneProps>(
  ({
    name,
    label,
    description,
    multiple = false,
    disabled = false,
    error: propsError = false,
    helperText: propsHelperText,
    files = [],
    onDrop,
    onFileDelete,
    accept = {
      "image/heif": [".heif"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/png": [".png"],
    },
  }) => {
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
      accept,
      disabled,
      multiple,
      maxSize: SizeInBytes.MB_10,
      noClick: false,
      noKeyboard: false,
      onDrop: (acceptedFiles: File[]) => onDrop(acceptedFiles),
    });

    const { error, helperText } = useMemo(
      () => ({
        error: propsError || fileRejections.length > 0,
        helperText: propsHelperText,
      }),
      [propsError, propsHelperText, fileRejections],
    );

    return (
      <Stack spacing={1}>
        <Typography
          component="label"
          htmlFor={name}
          sx={{
            textAlign: {
              xs: "left",
              md: "center",
            },
            opacity: disabled ? 0.6 : 1,
          }}
          variant="subtitle1"
        >
          {label || startCase(name)}
        </Typography>
        <Dropzone
          name={name}
          rootProps={getRootProps()}
          inputProps={getInputProps()}
          disabled={disabled}
          description={description}
          error={error}
          helperText={helperText}
          accept={accept}
        />
        {!!files.length && (
          <Box>
            <Grid container spacing={1}>
              {files.map((file, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={index}>
                  <FilePreview
                    file={file}
                    index={index}
                    disabled={disabled}
                    onDelete={onFileDelete}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Stack>
    );
  },
);
FilesDropzone.displayName = "FilesDropzone";
