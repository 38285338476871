import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  RequestMethod,
  transformTypeormListPageFromDto,
  transformTypeormListPageSearchQueryToDto,
} from "@novalabsxyz/api-client-core";
import type { ListPage } from "@novalabsxyz/api-client-core";
import { cpiApiClient } from "@novalabsxyz/cpi-api-client";
import type {
  GetRadioRegistrationDetailsQuery,
  RadioRegistrationDetails,
  RadioRegistrationWithStatus,
  RadioRegistrationsListPageSearchQuery,
  RadioRegistrationPrerequisites,
  RadioRegistrationPrerequisitesSearchQuery,
  RadioSerialNumber,
  RadioStatus,
  User,
} from "@novalabsxyz/cpi-api-client";

export const cpiApiRtk = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: cpiApiClient.baseUrl,
    prepareHeaders: (headers) => {
      if (cpiApiClient.accessToken) {
        headers.set("Authorization", `Bearer ${cpiApiClient.accessToken}`);
      }

      return headers;
    },
  }),
  reducerPath: "cpi",
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: cpiApiClient.routes.authCurrentUser.pathname,
        method: RequestMethod.Get,
      }),
    }),
    getRadioStatus: builder.query<RadioStatus, RadioSerialNumber>({
      query: ({ radioSerialNumber, ...searchQuery }) => ({
        url: cpiApiClient.appendQueryToUrl(
          cpiApiClient.routes.radioStatus.getPath({
            radioSerialNumber,
          }),
          searchQuery,
        ),
        method: RequestMethod.Get,
      }),
    }),
    getRadioRegistrationPrerequisites: builder.query<
      RadioRegistrationPrerequisites,
      RadioRegistrationPrerequisitesSearchQuery
    >({
      query: (searchQuery) => ({
        url: cpiApiClient.appendQueryToUrl(
          cpiApiClient.routes.radioRegistrationsPrerequisites.pathname,
          searchQuery,
        ),
        method: RequestMethod.Get,
      }),
    }),
    getRadioRegistrationList: builder.query<
      ListPage<RadioRegistrationWithStatus>,
      RadioRegistrationsListPageSearchQuery
    >({
      query: (searchQuery = {}) => ({
        url: cpiApiClient.appendQueryToUrl(
          cpiApiClient.routes.radioRegistrations.pathname,
          transformTypeormListPageSearchQueryToDto(searchQuery),
        ),
        method: RequestMethod.Get,
      }),
      transformResponse: transformTypeormListPageFromDto,
    }),
    getRadioRegistration: builder.query<
      RadioRegistrationDetails,
      GetRadioRegistrationDetailsQuery & { id: number }
    >({
      query: ({ id, ...query }) => ({
        url: cpiApiClient.appendQueryToUrl(
          cpiApiClient.routes.radioRegistration.getPath({ id }),
          query,
        ),
        method: RequestMethod.Get,
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetRadioStatusQuery,
  useGetRadioRegistrationPrerequisitesQuery,
  useGetRadioRegistrationListQuery,
  useGetRadioRegistrationQuery,
} = cpiApiRtk;
