import type { ChangeEvent } from "react";
import { memo, useCallback } from "react";

import { useField, useFormikContext } from "formik";

import { TextField } from "@novalabsxyz/components/core";
import type { TextFieldProps } from "@novalabsxyz/components/core";
import { assert } from "@novalabsxyz/utils/assert";

export interface FormFilesFieldProps
  extends Omit<TextFieldProps, "type" | "value" | "onChange" | "error"> {
  multiple?: boolean;
}

export const FormFilesField = memo<FormFilesFieldProps>(
  ({ name, helperText, disabled, multiple = false, ...props }) => {
    const [, { touched, error }, { setValue }] = useField<File[] | File | undefined>(name);
    const { isSubmitting } = useFormikContext();

    const handleChange = useCallback(
      ({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>): void => {
        assert(files, "Files list is not defined.");

        setValue(multiple ? [...files] : files[0]);
      },
      [multiple, setValue],
    );

    return (
      <TextField
        {...props}
        inputProps={{
          multiple,
          ...(props.inputProps || {}),
        }}
        type="file"
        name={name}
        onChange={handleChange}
        disabled={isSubmitting || disabled}
        error={touched && error !== undefined}
        helperText={(touched && error) || helperText}
      />
    );
  },
);
FormFilesField.displayName = "FormFilesField";
