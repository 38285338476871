import { forwardRef, memo, useMemo } from "react";

import type { CardProps } from "@novalabsxyz/components/core/mui";
import { Card } from "@novalabsxyz/components/core/mui";
import { Severity, getColorFromSeverity } from "@novalabsxyz/constants/severity";

export interface ActionItemStatusCardProps extends CardProps {
  severity?: Severity;
  defaultStatus?: Severity;
  disabled?: boolean;
  clickable?: boolean;
}

export const ActionItemStatusCard = memo<ActionItemStatusCardProps>(
  forwardRef(
    (
      { severity = Severity.Info, disabled = false, clickable = false, sx, children, ...rest },
      ref,
    ) => {
      const { backgroundColor, borderColor } = useMemo(() => {
        if (disabled) {
          return {
            backgroundColor: "grey.200",
          };
        }

        const baseColor = getColorFromSeverity(severity);
        return {
          backgroundColor: `${baseColor}.bleached`,
          borderColor: `${baseColor}.main`,
        };
      }, [disabled, severity]);

      return useMemo(
        () => (
          <Card
            sx={{
              paddingX: 2.5,
              paddingY: 2,
              borderRadius: 1,
              backgroundColor,
              cursor: clickable && !disabled ? "pointer" : "default",

              "&:focus": {
                borderColor,
              },

              ...sx,
            }}
            ref={ref}
            {...rest}
          >
            {children}
          </Card>
        ),
        [backgroundColor, borderColor, disabled, clickable, sx, children, ref, rest],
      );
    },
  ),
);
ActionItemStatusCard.displayName = "ActionItemStatusCard";
