import { memo, useMemo } from "react";
import type { ReactElement, ReactNode } from "react";

import type { SelectProps as MuiSelectProps } from "@mui/material";
import { Select as MuiSelect, FormControl, MenuItem, FormHelperText } from "@mui/material";

import type { InputWrapperProps } from "@novalabsxyz/components/core/inputs/wrapper";
import { InputWrapper } from "@novalabsxyz/components/core/inputs/wrapper";

export type { SelectChangeEvent } from "@mui/material";

export interface SelectOption {
  value: string | number;
  label?: string | number | ReactElement;
}

export interface SelectProps
  extends InputWrapperProps,
    Pick<
      MuiSelectProps,
      "value" | "defaultValue" | "required" | "onChange" | "onBlur" | "disabled"
    > {
  name: string;
  options: SelectOption[];
  optionForUndefined?: SelectOption;
  error?: boolean;
  helperText?: ReactNode;
  readOnly?: boolean;
}

export const Select = memo<SelectProps>(
  ({
    value,
    label,
    name,
    options: propsOptions,
    optionForUndefined,
    required,
    disabled,
    readOnly,
    tooltip,
    error,
    helperText,
    ...rest
  }) => {
    const options = useMemo(
      () => [...(optionForUndefined ? [optionForUndefined] : []), ...propsOptions],
      [propsOptions, optionForUndefined],
    );

    return (
      <InputWrapper
        name={name}
        label={label}
        tooltip={tooltip}
        required={required}
        disabled={disabled}
      >
        <FormControl id={name} error={error}>
          <MuiSelect readOnly={readOnly} name={name} value={value} disabled={disabled} {...rest}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label || option.value}
              </MenuItem>
            ))}
          </MuiSelect>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </InputWrapper>
    );
  },
);
Select.displayName = "Select";
