import { forwardRef, memo } from "react";

import type { AlertProps as MuiAlertProps } from "@mui/material";
import { Alert as MuiAlert, AlertTitle, IconButton, Snackbar as MuiSnackbar } from "@mui/material";

import type { Severity } from "@novalabsxyz/constants/severity";
import { CloseIcon } from "@novalabsxyz/icons";

export interface AlertProps extends MuiAlertProps {
  title?: string;
  severity?: Severity;
}

export const Alert = memo<AlertProps>(
  forwardRef(({ title, children, onClose, ...rest }, ref) => (
    <MuiAlert
      ref={ref}
      action={
        onClose ? (
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="extraSmall" />
          </IconButton>
        ) : undefined
      }
      {...rest}
    >
      {!!title && (
        <AlertTitle
          sx={{
            ...(!children && { marginBottom: 0 }),
          }}
        >
          {title}
        </AlertTitle>
      )}
      {children}
    </MuiAlert>
  )),
);
Alert.displayName = "Alert";

export interface SnackbarProps extends AlertProps {
  open: boolean;
  onClose: () => void;
}

export const AlertSnackbar = memo<SnackbarProps>(({ open, onClose, children, ...rest }) => (
  <MuiSnackbar open={open} onClose={onClose}>
    <Alert onClose={onClose} {...rest}>
      {children}
    </Alert>
  </MuiSnackbar>
));
AlertSnackbar.displayName = "AlertSnackbar";
