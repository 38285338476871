import axios from "axios";
import type {
  AxiosRequestConfig as RequestConfig,
  AxiosRequestHeaders as RequestHeaders,
  AxiosResponse as Response,
  AxiosInstance,
} from "axios";

import type { HttpError } from "@novalabsxyz/constants/http-status";
import { createHttpError, HttpStatus } from "@novalabsxyz/constants/http-status";
import { assert } from "@novalabsxyz/utils/assert";
import { isArray, isDictionary, isNumber, compactObject } from "@novalabsxyz/utils/lodash-plus";

import { RequestMethod } from "./constants";

export const parseResponseHttpError = (response: unknown): HttpError => {
  let status;
  let message = "";

  if (isDictionary(response)) {
    if (isNumber(response.status)) {
      status = response.status;
    }

    if (isDictionary(response.data)) {
      message = isArray(response.data.message)
        ? response.data.message.map((messagePart) => String(messagePart)).join("\n")
        : String(response.data.message);
    }
  }

  status = status || HttpStatus.INTERNAL_SERVER_ERROR;
  return createHttpError(status, message);
};

export type { RequestConfig, RequestHeaders, Response };
export interface ApiClientConfig {
  name: string;
  baseURL?: string;
  getRequestHeaders?: () => RequestHeaders;
}

export class ApiClientBase {
  protected name: string = ApiClientBase.name;

  private _baseUrl: string | undefined;
  private _axiosInstance: AxiosInstance | undefined;

  public getRequestHeaders?: () => RequestHeaders;

  constructor(
    { name, baseURL, getRequestHeaders }: ApiClientConfig,
    requestConfig: RequestConfig = {},
  ) {
    this.name = `${name} API client`;
    this._baseUrl = baseURL;
    this.getRequestHeaders = getRequestHeaders;

    if (baseURL !== undefined) {
      this._axiosInstance = axios.create({ baseURL, ...requestConfig });

      this._axiosInstance.interceptors.response.use(
        <T>({ data }: Response<T>) => data,
        ({ response }): void => {
          throw parseResponseHttpError(response);
        },
      );
    }
  }

  protected assertInitialized(
    condition: unknown,
    message = "Service is not available. Please, provide valid 'baseUrl'.",
  ): asserts condition {
    assert(condition, `${this.name}: ${message}`);
  }

  public get baseUrl(): string {
    this.assertInitialized(this._baseUrl);

    return this._baseUrl;
  }

  public get isAvailable(): boolean {
    return this._axiosInstance !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public appendQueryToUrl(url: string, params: unknown = {}): string {
    const searchQuery = new URLSearchParams(
      isDictionary(params) ? compactObject(params as Record<string, string>) : undefined,
    ).toString();

    return searchQuery ? `${url}?${searchQuery}` : url;
  }

  protected request<T, D = undefined>(
    method: string,
    url: string,
    data?: D,
    config?: RequestConfig,
  ): Promise<T> {
    this.assertInitialized(this._axiosInstance);

    return this._axiosInstance.request<never, T, D>({
      method,
      url,
      data,
      ...config,
      headers: {
        ...(config?.headers || {}),
        ...(this.getRequestHeaders ? this.getRequestHeaders() : {}),
      },
    });
  }

  protected get<T, D = undefined>(url: string, data?: D, config?: RequestConfig): Promise<T> {
    return this.request(RequestMethod.Get, this.appendQueryToUrl(url, data), undefined, config);
  }

  protected delete<T, D = undefined>(url: string, data?: D, config?: RequestConfig): Promise<T> {
    return this.request(RequestMethod.Delete, this.appendQueryToUrl(url, data), undefined, config);
  }

  protected post<T, D = undefined>(url: string, data?: D, config?: RequestConfig): Promise<T> {
    return this.request(RequestMethod.Post, url, data, config);
  }

  protected put<T, D = undefined>(url: string, data?: D, config?: RequestConfig): Promise<T> {
    return this.request(RequestMethod.Put, url, data, config);
  }

  protected patch<T, D = undefined>(url: string, data?: D, config?: RequestConfig): Promise<T> {
    return this.request(RequestMethod.Patch, url, data, config);
  }
}
