import { memo } from "react";

import {
  usePrimaryInterfaceQuery,
  useInternetAccessQuery,
  useMagmaAccessQuery,
  useHeliumDashboardAccessQuery,
  useZtpAccessQuery,
  useSerialIdQuery,
} from "@novalabsxyz/agw-client/store/api/gateway-stats";
import { Grid, Box } from "@novalabsxyz/components/core";
import { StatusCard } from "@novalabsxyz/components/ui/cards";
import { Severity } from "@novalabsxyz/constants/severity";
import { TimeInMilliseconds } from "@novalabsxyz/constants/time";

export const MinerPanelPrimary = memo(() => {
  const queryOptions = { pollingInterval: TimeInMilliseconds.OneMinute };

  const { isLoading: isPrimaryInterfaceLoading, data: primaryInterface } = usePrimaryInterfaceQuery(
    undefined,
    queryOptions,
  );
  const { isLoading: isInternetAccessLoading, data: internetAccess } = useInternetAccessQuery(
    undefined,
    queryOptions,
  );
  const { isLoading: isMagmaAccessLoading, data: magmaAccess } = useMagmaAccessQuery(
    undefined,
    queryOptions,
  );
  const { isLoading: isHeliumDashboardAccessLoading, data: heliumDashboardAccess } =
    useHeliumDashboardAccessQuery(undefined, queryOptions);

  const { isLoading: isZtpAccessLoading, data: ztpAccessLoading } = useZtpAccessQuery(
    undefined,
    queryOptions,
  );

  const { isLoading: isSerialIdLoading, data: serialId } = useSerialIdQuery(
    undefined,
    queryOptions,
  );

  return (
    <Box>
      <Grid
        container
        spacing={{
          xs: 2,
          sm: 3,
          md: 4,
        }}
      >
        <Grid item xs={12} md={6} lg={4}>
          <StatusCard
            title="EthO config"
            subtitle={primaryInterface?.config ? `Config: ${primaryInterface?.config}` : undefined}
            subtitleVariant="body2"
            valueLabel="State is:"
            value={primaryInterface?.status}
            severity={primaryInterface?.severity}
            isLoading={isPrimaryInterfaceLoading}
            data-testid="eth0config-card"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StatusCard
            title="Internet Access"
            subtitle="Internet Access is"
            value={internetAccess?.status}
            severity={internetAccess?.severity}
            isLoading={isInternetAccessLoading}
            data-testid="internet-access-card"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StatusCard
            title="Access to API Service"
            subtitle="API Access is"
            value={magmaAccess?.status}
            severity={magmaAccess?.severity}
            isLoading={isMagmaAccessLoading}
            data-testid="api-access-card"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StatusCard
            title="Access to Dashboard Service"
            subtitle="Dashboard Access is"
            value={heliumDashboardAccess?.status}
            severity={heliumDashboardAccess?.severity}
            isLoading={isHeliumDashboardAccessLoading}
            data-testid="dashboard-access-card"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StatusCard
            title="Access to Provisioning Service"
            subtitle="Provisioning Access is"
            value={ztpAccessLoading?.status}
            severity={ztpAccessLoading?.severity}
            isLoading={isZtpAccessLoading}
            data-testid="provisioning-access-card"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StatusCard
            title="Serial ID"
            value={serialId?.serialId}
            severity={Severity.Success}
            valueVariant="h4"
            isLoading={isSerialIdLoading}
            data-testid="serial-id-card"
          />
        </Grid>
      </Grid>
    </Box>
  );
});
MinerPanelPrimary.displayName = "MinerPanelPrimary";
