import type { IsAccessible } from "./utils";
import { transformAccessibiltyResponse } from "./utils";

export type HeliumDashboardAccess = IsAccessible;
export const heliumDashboardAccessQuery = {
  query: () => ({
    url: "/helium-dashboard-access",
    method: "GET",
  }),
  transformResponse: transformAccessibiltyResponse,
};
