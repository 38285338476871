import type { IsAccessible } from "./utils";
import { transformAccessibiltyResponse } from "./utils";

export type ZtpAccess = IsAccessible;
export const ztpAccessQuery = {
  query: () => ({
    url: "/ztp-access",
    method: "GET",
  }),
  transformResponse: transformAccessibiltyResponse,
};
