import type { Severity } from "@novalabsxyz/constants/severity";
import type { AvailabilityStatus } from "@novalabsxyz/constants/status";
import { boolToAvailabilityStatus, availabilityToSeverity } from "@novalabsxyz/constants/status";

type IsAccessibleDTO = {
  isAccessible: boolean;
};

export type IsAccessible = {
  status: AvailabilityStatus;
  severity: Severity;
};

export const transformAccessibiltyResponse = (data: IsAccessibleDTO): IsAccessible => {
  const status = boolToAvailabilityStatus(data.isAccessible);

  return {
    status,
    severity: availabilityToSeverity(status),
  };
};
