import { Severity } from "@novalabsxyz/constants/severity";

export enum AvailabilityStatus {
  NotAvailable = "N/A",
  Ok = "OK",
}

export const boolToAvailabilityStatus = (value?: boolean): AvailabilityStatus => {
  switch (value) {
    case true:
      return AvailabilityStatus.Ok;
    case false:
    case undefined:
    default:
      return AvailabilityStatus.NotAvailable;
  }
};

export const availabilityToSeverity = (
  status: AvailabilityStatus = AvailabilityStatus.NotAvailable,
): Severity => {
  const statusMap = {
    [AvailabilityStatus.NotAvailable]: Severity.Error,
    [AvailabilityStatus.Ok]: Severity.Success,
  };

  return statusMap[status];
};
