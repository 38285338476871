export enum Severity {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export const boolToSeverity = (value?: boolean): Severity => {
  switch (value) {
    case false:
      return Severity.Error;
    case true:
      return Severity.Success;
    case undefined:
    default:
      return Severity.Info;
  }
};

export const getColorFromSeverity = (
  status = Severity.Info,
): "success" | "warning" | "error" | "info" => status;
