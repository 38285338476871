import {
  ApiClientBase,
  transformTypeormListPageFromDto,
  transformTypeormListPageSearchQueryToDto,
} from "@novalabsxyz/api-client-core";
import type {
  RequestConfig,
  ListPage,
  TypeormListPageDto,
  TypeormListPageSearchQueryDto,
} from "@novalabsxyz/api-client-core";
import { getEnvValue } from "@novalabsxyz/utils/env";
import { transformManyToBase64 } from "@novalabsxyz/utils/file";
import { yup } from "@novalabsxyz/utils/yup";

import type { RadioRegistrationDataDto } from "./dto";
import type {
  SignInData,
  SignInResponse,
  User,
  RadioRegistrationData,
  RadioRegistrationDetails,
  RadioStatus,
  UpdateRadioRegistrationStatusData,
  RadioRegistrationWithStatus,
  PatchRadioRegistrationData,
  RadioRegistrationsListPageSearchQuery,
  RadioRegistrationsAssignReviewersData,
  RadioRegistrationsAssignReviewersResponse,
  GetRadioRegistrationDetailsQuery,
  CreateRadioRegistrationResponse,
  RadioSerialNumber,
} from "./types";

export class CpiApiClient extends ApiClientBase {
  // TODO: Work on routes. Make a service, or integrate into API Client Base.
  // TODO: Make "getPath" function automatically generated from pathname.
  public readonly routes = {
    authSignIn: {
      pathname: "auth/sign-in",
    },
    authCurrentUser: {
      pathname: "auth/me",
    },
    radioStatus: {
      pathname: "radios/:radioSerialNumber/status",
      getPath: ({ radioSerialNumber }: { radioSerialNumber: string }): string =>
        `radios/${radioSerialNumber}/status`,
    },
    radioRegistrations: {
      pathname: "radios/registrations",
    },
    radioRegistrationsPrerequisites: {
      pathname: "radios/registrations/prerequisites",
    },
    radioRegistration: {
      pathname: "radios/registrations/:id",
      getPath: ({ id }: { id: number }): string => `radios/registrations/${id}`,
    },
    radioRegistrationStatus: {
      pathname: "radios/registrations/:id/status",
      getPath: ({ id }: { id: number }): string => `radios/registrations/${id}/status`,
    },
    radioRegistrationsAssignReviewers: {
      pathname: "radios/registrations/reviewers/assign",
    },
  };

  private readonly _accessTokenKey = "ffcpiat";
  public get accessToken(): string | null {
    return localStorage.getItem(this._accessTokenKey);
  }
  private _saveAccessToken = (token: string): void =>
    localStorage.setItem(this._accessTokenKey, token);
  private _removeAccessToken = (): void => localStorage.removeItem(this._accessTokenKey);

  constructor() {
    super({
      name: "CPI",
      baseURL: getEnvValue("PUBLIC_CPI_API_BASE_URL", yup.string().required()),
      getRequestHeaders: () => ({
        ...(this.accessToken ? { Authorization: `Bearer ${this.accessToken}` } : {}),
      }),
    });
  }

  public signIn = async (data: SignInData, config?: RequestConfig): Promise<void> => {
    const { accessToken } = await this.post<SignInResponse, SignInData>(
      this.routes.authSignIn.pathname,
      data,
      config,
    );

    this._saveAccessToken(accessToken);
  };
  public signOut = this._removeAccessToken;

  public getCurrentUser = (config?: RequestConfig): Promise<User> => {
    return this.get<User>(this.routes.authCurrentUser.pathname, undefined, config);
  };

  public getRadioStatus = (
    { radioSerialNumber }: RadioSerialNumber,
    config?: RequestConfig,
  ): Promise<RadioStatus> => {
    return this.get<RadioStatus>(
      this.routes.radioStatus.getPath({ radioSerialNumber: radioSerialNumber }),
      undefined,
      config,
    );
  };

  public getRadioRegistrationList = async (
    searchQuery: RadioRegistrationsListPageSearchQuery = {},
    config?: RequestConfig,
  ): Promise<ListPage<RadioRegistrationWithStatus>> => {
    const data = await this.get<
      TypeormListPageDto<RadioRegistrationWithStatus>,
      TypeormListPageSearchQueryDto
    >(
      this.routes.radioRegistrations.pathname,
      transformTypeormListPageSearchQueryToDto(searchQuery),
      config,
    );

    return transformTypeormListPageFromDto(data);
  };

  public postRadioRegistration = async (
    data: RadioRegistrationData,
    config?: RequestConfig,
  ): Promise<CreateRadioRegistrationResponse> => {
    const [heightImages, azimuthImages, elevationImages, modelPlateImages, overallImages] =
      await Promise.all([
        transformManyToBase64(data.heightImages),
        transformManyToBase64(data.azimuthImages),
        transformManyToBase64(data.elevationImages),
        transformManyToBase64(data.modelPlateImages),
        transformManyToBase64(data.overallImages),
      ]);

    return this.post<CreateRadioRegistrationResponse, RadioRegistrationDataDto>(
      this.routes.radioRegistrations.pathname,
      {
        ...data,
        heightImages,
        azimuthImages,
        elevationImages,
        modelPlateImages,
        overallImages,
      },
      config,
    );
  };

  public getRadioRegistration = (
    { id, ...query }: GetRadioRegistrationDetailsQuery & { id: number },
    config?: RequestConfig,
  ): Promise<RadioRegistrationDetails> => {
    return this.get<RadioRegistrationDetails, GetRadioRegistrationDetailsQuery>(
      this.routes.radioRegistration.getPath({ id }),
      query,
      config,
    );
  };

  public patchRadioRegistration = (
    { id, ...data }: PatchRadioRegistrationData & { id: number },
    config?: RequestConfig,
  ): Promise<void> => {
    return this.patch<void, PatchRadioRegistrationData>(
      this.routes.radioRegistration.getPath({ id }),
      data,
      config,
    );
  };

  public updateRadioRegistrationStatus = (
    { id, ...data }: UpdateRadioRegistrationStatusData & { id: number },
    config?: RequestConfig,
  ): Promise<void> => {
    return this.post<void, UpdateRadioRegistrationStatusData>(
      this.routes.radioRegistrationStatus.getPath({ id }),
      data,
      config,
    );
  };

  public assignReviewersToRadioRegistrations = (
    data: RadioRegistrationsAssignReviewersData,
    config?: RequestConfig,
  ): Promise<RadioRegistrationsAssignReviewersResponse> => {
    return this.post<
      RadioRegistrationsAssignReviewersResponse,
      RadioRegistrationsAssignReviewersData
    >(this.routes.radioRegistrationsAssignReviewers.pathname, data, config);
  };
}
