import { memo } from "react";

import type { DialogContentProps as MuiDialogContentProps } from "@mui/material";
import { DialogContent as MuiDialogContent } from "@mui/material";

export interface DialogContentProps extends MuiDialogContentProps {
  disableTopPadding?: boolean;
}

export const DialogContent = memo<DialogContentProps>(
  ({ disableTopPadding = false, children, ...props }) => (
    <MuiDialogContent
      {...props}
      sx={{
        paddingTop: disableTopPadding ? 0 : undefined,
        ...props.sx,
      }}
    >
      {children}
    </MuiDialogContent>
  ),
);
DialogContent.displayName = "DialogContent";
