import { Severity } from "@novalabsxyz/constants/severity";

export enum UpDownStatus {
  Down = "down",
  Up = "up",
}

export const boolToUpDownStatus = (value?: boolean): UpDownStatus => {
  switch (value) {
    case true:
      return UpDownStatus.Up;
    case false:
    case undefined:
    default:
      return UpDownStatus.Down;
  }
};

export const upDownToSeverity = (status: UpDownStatus = UpDownStatus.Down): Severity => {
  const statusMap = {
    [UpDownStatus.Down]: Severity.Error,
    [UpDownStatus.Up]: Severity.Success,
  };

  return statusMap[status];
};
