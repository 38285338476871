import type { RadioModel } from "@novalabsxyz/constants/radio";
import { Severity } from "@novalabsxyz/constants/severity";
import type { ConnectionStatus, OnOffStatus } from "@novalabsxyz/constants/status";

export enum GpsStatus {
  Trying = 0,
  Locked = 1,
}

// TODO: Move to the corresponding client feature.
export const gpsStatusToSeverity = (status: GpsStatus = GpsStatus.Trying): Severity => {
  const statusMap = {
    [GpsStatus.Locked]: Severity.Success,
    [GpsStatus.Trying]: Severity.Error,
  };

  return statusMap[status];
};

export enum SpectrumStatus {
  None = 0,
  Trying = 1,
  Connected = 2,
  Success = 3,
  Failure = 4,
}

// TODO: Move to the corresponding client feature.
export const spectrumStatusToSeverity = (
  status: SpectrumStatus = SpectrumStatus.None,
): Severity => {
  const statusMap = {
    [SpectrumStatus.None]: Severity.Info,
    [SpectrumStatus.Trying]: Severity.Error,
    [SpectrumStatus.Connected]: Severity.Warning,
    [SpectrumStatus.Success]: Severity.Success,
    [SpectrumStatus.Failure]: Severity.Error,
  };

  return statusMap[status];
};

export interface RadioInfo {
  manufacturer?: string;
  productClass?: string;
  model?: RadioModel;
  serial: string;
  softwareVersion: string;
  hardwareVersion: string;
  gpsStatus: GpsStatus;
  isGpsReady: boolean;
  spectrumStatus: SpectrumStatus;
  coreConnectionStatus: ConnectionStatus;
  airStatus: OnOffStatus;
  cpiEnabled: boolean;
  latitude: number;
  longitude: number;
  dpEnabled: boolean;
  dpResponseCode?: number;
  dpResponseMessage: string;
  isAntennaExternal: boolean;
  transmitEnabled: boolean;
  isActivated: boolean;
  migration: {
    consentRequired: boolean;
    consentGiven: boolean;
  };
}
