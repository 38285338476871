import { Fade } from "@mui/material";
import { createTheme as createMuiTheme, alpha } from "@mui/material/styles";

import {
  ApproveFilledIcon,
  ExclamationMarkTriangleFilledIcon,
  RejectFilledIcon,
  SortOrderIcon,
} from "@novalabsxyz/icons";

import { Colors } from "./colors";

const DEFAULT_SPACING_PX = 8;
const BODY_FONT_SIZE_PX = 16;
const DEFAULT_LINE_HEIGHT = 1.315;
const pxToRem = (pixels: number): string => `${pixels / BODY_FONT_SIZE_PX}rem`;

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: Colors.MainYellow,
      dark: Colors.MainYellowDark,
    },
    secondary: {
      main: Colors.Green,
      contrastText: Colors.White,
      bleached: Colors.BleachedGreen,
      bleachedDark: Colors.BleachedGreenDark,
      bleachedContrastText: Colors.Green,
    },
    tertiary: {
      main: Colors.AccentBlue,
    },
    info: {
      main: Colors.DarkGray,
      contrastText: Colors.White,
      bleached: Colors.BleachedGray,
      bleachedDark: Colors.BleachedGrayDark,
      bleachedContrastText: Colors.DarkGray,
    },
    success: {
      main: Colors.Green,
      contrastText: Colors.White,
      bleached: Colors.BleachedGreen,
      bleachedDark: Colors.BleachedGreenDark,
      bleachedContrastText: Colors.Green,
    },
    warning: {
      main: Colors.Orange,
      contrastText: Colors.White,
      bleached: Colors.BleachedOrange,
      bleachedDark: Colors.BleachedOrangeDark,
      bleachedContrastText: Colors.Orange,
    },
    error: {
      main: Colors.Red,
      contrastText: Colors.White,
      bleached: Colors.BleachedRed,
      bleachedDark: Colors.BleachedRedDark,
      bleachedContrastText: Colors.Red,
    },
    text: {
      primary: Colors.DarkGray,
      primaryLighter: Colors.DarkGrayLighter,
      secondary: Colors.Green,
      caption: Colors.Gray,
    },
    background: {
      paper: Colors.White,
      default: Colors.LightGray,
      contrast: Colors.Black,
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    fontSize: BODY_FONT_SIZE_PX,
    h1: {
      fontSize: pxToRem(50),
      fontWeight: 800,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    h2: {
      fontSize: pxToRem(36),
      fontWeight: 700,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    h3: {
      fontSize: pxToRem(25),
      fontWeight: 700,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    h4: {
      fontSize: pxToRem(21),
      fontWeight: 700,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    h5: {
      fontSize: pxToRem(18),
      fontWeight: 700,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    subtitle1: {
      fontSize: pxToRem(16),
      fontWeight: 700,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    subtitle2: {
      fontSize: pxToRem(14),
      fontWeight: 700,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    body1: {
      fontSize: pxToRem(16),
      fontWeight: 400,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    body2: {
      fontSize: pxToRem(14),
      fontWeight: 400,
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
    button: {
      fontSize: pxToRem(17),
      lineHeight: 22 / 17,
      fontWeight: 600,
    },
    caption: {
      fontSize: pxToRem(12),
      lineHeight: DEFAULT_LINE_HEIGHT,
    },
  },
});

const { palette, spacing, breakpoints, transitions, typography, zIndex } = theme;

theme.components = {
  MuiAlert: {
    defaultProps: {
      variant: "filled",
      iconMapping: {
        success: <ApproveFilledIcon color="success" />,
        info: <ExclamationMarkTriangleFilledIcon color="info" />,
        warning: <ExclamationMarkTriangleFilledIcon color="warning" />,
        error: <RejectFilledIcon color="error" />,
      },
    },
    styleOverrides: {
      root: {
        padding: spacing(1.5, 2),
      },
      icon: {
        paddingTop: spacing(0.375),
        paddingBottom: spacing(0.375),
        marginRight: spacing(2.5),
      },
      action: {
        paddingTop: spacing(0.375),
        paddingBottom: spacing(0.375),
      },
      message: {
        fontSize: typography.caption.fontSize,
        lineHeight: typography.caption.lineHeight,
        padding: spacing(1, 0, 1),
      },
      ...Object.fromEntries(
        Object.entries({
          filledSuccess: "success",
          filledError: "error",
          filledInfo: "info",
          filledWarning: "warning",
        } as const).map(([ruleName, color]) => [
          ruleName,
          {
            backgroundColor: palette[color].bleached,
            color: palette.text.primary,
          },
        ]),
      ),
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontSize: typography.body2.fontSize,
        fontWeight: 700,
        lineHeight: typography.body2.lineHeight,
        marginBottom: spacing(1.5),
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        zIndex: zIndex.drawer + 1,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: "contained",
    },
    styleOverrides: {
      root: {
        textTransform: "none",
      },
      containedPrimary: {
        "&.Mui-disabled": {
          backgroundColor: alpha(palette.primary.main, 0.15),
          color: alpha(palette.primary.contrastText, 0.3),
        },
      },
      containedSecondary: {
        backgroundColor: palette.secondary.bleached,
        color: palette.secondary.bleachedContrastText,

        "&.Mui-disabled": {
          backgroundColor: alpha(palette.secondary.bleached || palette.secondary.main, 0.15),
          color: alpha(
            palette.secondary.bleachedContrastText || palette.secondary.contrastText,
            0.3,
          ),
        },

        "&:hover": {
          backgroundColor: palette.secondary.main,
          color: palette.secondary.contrastText,
        },
      },
      ...Object.fromEntries(
        Object.entries({
          containedSuccess: "success",
          containedError: "error",
          containedInfo: "info",
          containedWarning: "warning",
        } as const).map(([ruleName, color]) => [
          ruleName,
          {
            backgroundColor: palette[color].bleached,
            color: palette[color].bleachedContrastText,

            "&.Mui-disabled": {
              backgroundColor: alpha(palette[color].bleached || palette[color].main, 0.15),
              color: alpha(palette[color].bleachedContrastText || palette[color].contrastText, 0.3),
            },

            "&:hover": {
              backgroundColor: palette[color].bleachedDark,
            },
          },
        ]),
      ),
      sizeMedium: {
        minWidth: 160,
        padding: spacing(1.75, 2.5),
      },
      sizeSmall: {
        lineHeight: 18 / 13,
        fontSize: pxToRem(13),
        minWidth: 100,
        padding: spacing(1.375, 2.5),
      },
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: spacing(2, 3),

        "&:last-child": {
          paddingBottom: spacing(2),
        },
      },
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      color: "secondary",
    },
  },
  MuiContainer: {
    defaultProps: {
      maxWidth: "xxl",
    },
  },
  MuiDialog: {
    defaultProps: {
      scroll: "body",
      fullWidth: true,
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: "center",
        padding: spacing(2.5),
        paddingTop: 0,
        paddingBottom: spacing(3),
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: spacing(2.5),
        paddingBottom: spacing(3),
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      color: "tertiary",
      fullWidth: true,
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: palette.info.main,
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: "small",
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: pxToRem(14),
        lineHeight: 18 / 14,
      },
      input: {
        padding: spacing(15 / DEFAULT_SPACING_PX),
      },
      inputMultiline: {
        padding: "0!important",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
    styleOverrides: {
      root: {
        transition: transitions.create("color"),

        "&:not(.Mui-disabled)": {
          cursor: "pointer",
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.paper,

        "&:not(.Mui-disabled)": {
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.tertiary.main,
            },
          },
        },

        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
          },
        },
      },
      input: {
        padding: spacing(15 / DEFAULT_SPACING_PX),
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 1,
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      TransitionComponent: Fade,
    },
    styleOverrides: {
      root: {
        maxWidth: `calc(100% - ${spacing(2)})`,
        width: breakpoints.values.sm,

        [breakpoints.up("sm")]: {
          maxWidth: `calc(100% - ${spacing(6)})`,
        },
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      labelContainer: {
        color: palette.info.main,
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      // Please keep in mind that if you have MUI icons in gallery, this setting should be set to 'false' for them.
      // `true` should be applied to custom icons.
      inheritViewBox: true,
    },
    styleOverrides: {
      root: {
        fontSize: 24,
      },
      fontSizeLarge: {
        fontSize: 28,
      },
      fontSizeSmall: {
        fontSize: 20,
      },
      fontSizeExtraSmall: {
        fontSize: 16,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        ...typography.h4,
        textTransform: "none",
        color: palette.info.light,
        padding: spacing(1.5, 0),

        "&.Mui-selected": {
          color: palette.text.primary,
        },
        "& + .MuiTab-root": {
          marginLeft: spacing(3),
        },
        [breakpoints.up("md")]: {
          ...typography.h3,
          padding: spacing(4, 0),

          "& + .MuiTab-root": {
            marginLeft: spacing(6),
          },
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: palette.secondary.main,
        height: 3,
      },
    },
  },
  MuiTableSortLabel: {
    defaultProps: {
      IconComponent: SortOrderIcon,
    },
    styleOverrides: {
      root: {
        transition: transitions.create("color"),

        "&.Mui-active": {
          "&:hover": {
            color: palette.secondary.main,
          },

          "& .MuiTableSortLabel-icon": {
            color: palette.info.bleachedDark,
          },

          "& .MuiTableSortLabel-iconDirectionAsc": {
            transform: "rotate(0)",

            "& [class$='up']": {
              fill: palette.secondary.main,
            },
          },

          "& .MuiTableSortLabel-iconDirectionDesc": {
            transform: "rotate(0)",

            "& [class$='down']": {
              fill: palette.secondary.main,
            },
          },
        },

        "&:hover": {
          "& .MuiTableSortLabel-icon": {
            color: palette.secondary.main,
            opacity: 1,
          },
        },
      },
      icon: {
        transition: transitions.create("color"),
        color: palette.info.bleachedDark,
        opacity: 1,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      color: "tertiary",
      fullWidth: true,
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: 60,

        [breakpoints.up("sm")]: {
          minHeight: 60,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        maxWidth: "30rem",
        backgroundColor: palette.background.paper,
        color: palette.text.primary,
        padding: spacing(2.5, 3.5),
        boxShadow: "0px 7px 27px rgba(0, 0, 0, 0.09)",
        borderRadius: 15,
      },
      arrow: {
        color: palette.background.paper,
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        caption: "p",
      },
    },
  },
};
