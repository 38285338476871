import { memo, useCallback, useMemo } from "react";

import { useField, useFormikContext } from "formik";

import { Checkbox } from "@novalabsxyz/components/core";
import type { CheckboxProps } from "@novalabsxyz/components/core";

export const FormCheckboxField = memo<Omit<CheckboxProps, "value" | "onChange" | "error">>(
  ({ name, helperText, disabled, ...props }) => {
    const [{ value, onChange }, { touched, error }, { setTouched }] = useField<string>(name);
    const { isSubmitting, validateOnMount } = useFormikContext();

    const handleBlur = useCallback(() => {
      setTouched(true);
    }, [setTouched]);

    return useMemo(
      () => (
        <Checkbox
          {...props}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          disabled={isSubmitting || disabled}
          error={(validateOnMount || touched) && error !== undefined}
          helperText={((validateOnMount || touched) && error) || helperText}
        />
      ),
      [
        name,
        value,
        error,
        helperText,
        disabled,
        touched,
        isSubmitting,
        validateOnMount,
        props,
        onChange,
        handleBlur,
      ],
    );
  },
);
FormCheckboxField.displayName = "FormCheckboxField";
