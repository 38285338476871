import { memo } from "react";
import type { ReactNode } from "react";

import { ServiceConfig } from "@novalabsxyz/client-config";
import { Container, Stack, Typography } from "@novalabsxyz/components/core";

export interface FooterProps {
  fixedContainer?: boolean;
  children?: ReactNode;
}

export const Footer = memo<FooterProps>(({ fixedContainer, children }) => (
  <Container component="footer" fixed={fixedContainer} sx={{ paddingY: 5 }}>
    <Stack spacing={3}>
      {children}
      <Typography variant="body2">Version {ServiceConfig.Version}</Typography>
    </Stack>
  </Container>
));
Footer.displayName = "Footer";
